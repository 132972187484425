import { Box, Button, CardContent, CardMedia, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BaseScreen } from "~/common/components/base-screen/base-screen";
import GenericListPage from "~/common/components/generic-list-page/generic-list-page";
import { getNews } from "~/features/news/services/get-news";
import { News } from "~/features/news/types/news";
import { cleanUpText } from "~/utils/clean-up-text";

const NewsListPage = () => {
  const navigate = useNavigate();

  return (
    <BaseScreen>
      <GenericListPage<News>
        title="Notícias"
        fetchItems={(page, pageSize) => getNews(page, pageSize)}
        pageSize={9}
        getItemLink={(news) => `/noticia/${news.id}`}
        renderItemContent={(news) => (
          <Box>
            <CardMedia
              component="img"
              height="180"
              image={news.imageUrl || "/placeholder-image.jpg"}
              alt={news.title}
              loading="lazy"
            />
            <CardContent>
              <Typography variant="h6" component="div" gutterBottom>
                {news.title}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {cleanUpText(news.content ?? "")}
              </Typography>
            </CardContent>
          </Box>
        )}
        renderItemActions={(news) => (
          <Button
            variant="contained"
            color="primary"
            sx={{ width: "40%", marginTop: "8px", marginBottom: "16px" }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/noticia/${news.id}`);
            }}
          >
            Saiba Mais
          </Button>
        )}
      />
    </BaseScreen>
  );
};

export default NewsListPage;
