import DOMPurify from "dompurify";

export const formatAndSanitizeHTML = (rawHTML: string): { __html: string } => {
  if (!rawHTML) {
    return { __html: "Sem descrição" };
  }

  let formattedHTML = rawHTML.replace(/\t/g, "&emsp;&emsp;");

  formattedHTML = formattedHTML.replace(/\n/g, "<br />");

  formattedHTML = formattedHTML.replace(/<(\w+)[^>]*>\s*<\/\1>/g, "<br />");

  formattedHTML = formattedHTML.replace(
    /<li>(.*?)<\/li>/g,
    '<li style="margin-left: 3em; padding-left: 1em">$1</li>',
  );

  const sanitizedHTML = DOMPurify.sanitize(formattedHTML, {
    ALLOWED_TAGS: [
      "a",
      "b",
      "i",
      "u",
      "strong",
      "em",
      "p",
      "div",
      "span",
      "br",
      "ul",
      "ol",
      "li",
      "h1",
      "h2",
      "h3",
      "blockquote",
      "code",
      "pre",
    ],
    ALLOWED_ATTR: ["href", "target", "rel", "style"],
  });

  return { __html: sanitizedHTML };
};
