import React, { useState } from "react";
import {
  FiltersContainer,
  SectionTitle,
  StyledDivider,
  PriceRangeBox,
  StyledSlider,
  ApplyButton,
} from "./filter.styles";
import { Box, FormControlLabel, Checkbox, Typography } from "@mui/material";
import { FiltersState } from "~/common/entities/product";
import SidebarSearchBar from "~/features/shop/components/sidebar-searchbar/sidebar-searchbar";

interface FiltersProps {
  setFilters: React.Dispatch<React.SetStateAction<FiltersState>>;
  categories: { id: number; name: string }[];
}

export const Filters: React.FC<FiltersProps> = ({ setFilters, categories }) => {
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  const [priceRange, setPriceRange] = useState<number[]>([0, 550]);

  const handleCategoryChange = (id: number) => {
    const newCategories = selectedCategories.includes(id)
      ? selectedCategories.filter((catId) => catId !== id)
      : [...selectedCategories, id];

    setSelectedCategories(newCategories);
    setFilters((prev) => ({
      ...prev,
      categoryId: newCategories,
    }));
  };

  const handleApplyPriceFilter = () => {
    setFilters((prev) => ({
      ...prev,
      priceRange,
    }));
  };

  return (
    <FiltersContainer>
      <SectionTitle variant="h6">Filtros de Busca</SectionTitle>

      <Box>
        <SidebarSearchBar
          onSearch={(term) =>
            setFilters((prev) => ({ ...prev, searchTerm: term }))
          }
        />
        <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 600 }}>
          Categorias
        </Typography>
        {categories.map((category) => (
          <FormControlLabel
            key={category.id}
            control={
              <Checkbox
                checked={selectedCategories.includes(category.id)}
                onChange={() => handleCategoryChange(category.id)}
              />
            }
            label={category.name}
          />
        ))}
      </Box>

      <StyledDivider />

      <Box>
        <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 600 }}>
          Preço
        </Typography>
        <PriceRangeBox>
          <Typography variant="body2" color="text.secondary" mr={5}>
            Mínimo: R$ {priceRange[0].toFixed(2)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Máximo: R$ {priceRange[1].toFixed(2)}
          </Typography>
        </PriceRangeBox>
        <StyledSlider
          value={priceRange}
          onChange={(event, newValue) =>
            Array.isArray(newValue) && setPriceRange(newValue)
          }
          min={0}
          max={700}
          step={50}
          marks
          valueLabelDisplay="auto"
        />
        <ApplyButton onClick={handleApplyPriceFilter}>
          Aplicar Filtro
        </ApplyButton>
      </Box>
    </FiltersContainer>
  );
};
