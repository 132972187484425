import { Box, Skeleton, Stack, useTheme } from "@mui/material";
import { BaseScreen } from "~/common/components/base-screen/base-screen";

export const HomeLoadingSkeleton = () => {
  return (
    <BaseScreen>
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={400}
          animation="wave"
        />

        <Box sx={{ py: 4, px: 2 }}>
          <Stack spacing={2} alignItems="center">
            <Skeleton variant="text" width="60%" height={40} />
            <Skeleton variant="text" width="80%" height={20} />
            <Skeleton variant="text" width="80%" height={20} />
          </Stack>
        </Box>

        <Box sx={{ py: 4, bgcolor: "grey.100" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
              px: 2,
              gap: 2,
            }}
          >
            {[1, 2, 3, 4].map((item) => (
              <Box key={item} sx={{ textAlign: "center" }}>
                <Skeleton
                  variant="circular"
                  width={80}
                  height={80}
                  sx={{ mx: "auto" }}
                />
                <Skeleton
                  variant="text"
                  width={100}
                  height={20}
                  sx={{ mt: 1 }}
                />
              </Box>
            ))}
          </Box>
        </Box>

        <Box sx={{ py: 4, px: 2 }}>
          <Skeleton variant="text" width={300} height={40} sx={{ mb: 3 }} />
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {[1, 2, 3].map((item) => (
              <Box key={item} sx={{ width: 280 }}>
                <Skeleton variant="rectangular" width={280} height={200} />
                <Skeleton
                  variant="text"
                  width="80%"
                  height={20}
                  sx={{ mt: 1 }}
                />
                <Skeleton variant="text" width="60%" height={20} />
              </Box>
            ))}
          </Box>
        </Box>

        <Box sx={{ py: 4, px: 2, bgcolor: "grey.100" }}>
          <Skeleton variant="text" width={200} height={40} sx={{ mb: 3 }} />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                sm: "1fr 1fr",
                md: "1fr 1fr 1fr",
              },
              gap: 2,
            }}
          >
            {[1, 2, 3].map((item) => (
              <Box key={item}>
                <Skeleton variant="rectangular" width="100%" height={200} />
                <Skeleton
                  variant="text"
                  width="80%"
                  height={20}
                  sx={{ mt: 1 }}
                />
                <Skeleton variant="text" width="60%" height={20} />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </BaseScreen>
  );
};
