import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useNavigate } from "react-router-dom";
import {
  ActionGrid,
  DetailBox,
  PaperStyled,
  StyledButton,
  Subtitle,
  Title,
} from "./confirmation.styles";
import { getSocket } from "~/common/socket/socket";
import { useMediaQuery } from "@mui/system";
import theme from "~/theme/theme";
import { CustomSnackBar } from "~/common/components/custom-snackbar";

interface ConfirmationProps {
  orderId: string | null;
  paymentInfo: {
    status: string;
    qrCode?: string;
    qrCodeImage?: string;
    boletoUrl?: string;
  } | null;
}

const Confirmation: React.FC<ConfirmationProps> = ({
  orderId,
  paymentInfo,
}) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [currentPaymentInfo, setCurrentPaymentInfo] = useState(paymentInfo);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [approvalSnackbarOpen, setApprovalSnackbarOpen] = useState(false);

  useEffect(() => {
    const socket = getSocket();

    if (orderId) {
      socket.emit("JOIN_ORDER_ROOM", orderId);
    }

    const handleOrderStatusChange = (data: any) => {
      if (data.orderId === orderId) {
        setCurrentPaymentInfo((prev) => {
          if (!prev) return { status: data.newStatus };
          return { ...prev, status: data.newStatus };
        });
      }

      if (data.newStatus === "approved") {
        setApprovalSnackbarOpen(true);
      }
    };

    socket.on("ORDER_STATUS_CHANGED", handleOrderStatusChange);

    return () => {
      socket.off("ORDER_STATUS_CHANGED", handleOrderStatusChange);
    };
  }, [orderId]);

  const getPaymentStatusDetails = (info: ConfirmationProps["paymentInfo"]) => {
    if (!info) return null;

    const statusMap = {
      pending: {
        label: "Pendente",
        color: "warning",
        icon: (
          <PendingActionsIcon
            sx={{ fontSize: 80, color: "warning.main", mb: 1 }}
          />
        ),
        message:
          "Estamos aguardando seu pagamento! Assim que concluído, o produto estará disponível em 'Meus Pedidos'.",
      },
      approved: {
        label: "Aprovado",
        color: "success",
        icon: (
          <CheckCircleIcon
            sx={{ fontSize: 80, color: "success.main", mb: 1 }}
          />
        ),
        message: "Pagamento confirmado com sucesso!",
      },
      rejected: {
        label: "Rejeitado",
        color: "error",
        icon: <ErrorIcon sx={{ fontSize: 80, color: "error.main", mb: 1 }} />,
        message:
          "Pagamento não aprovado. Verifique os detalhes e tente novamente.",
      },
    };

    return (
      statusMap[info.status as keyof typeof statusMap] || {
        label: "Desconhecido",
        color: "default",
        icon: (
          <ErrorIcon sx={{ fontSize: 80, color: "text.secondary", mb: 1 }} />
        ),
        message: "Status do pagamento não reconhecido.",
      }
    );
  };

  const paymentStatusDetails = getPaymentStatusDetails(currentPaymentInfo);

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setSnackbarOpen(true);
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCloseApprovalSnackbar = () => {
    setApprovalSnackbarOpen(false);
  };

  return (
    <PaperStyled elevation={3}>
      <Box textAlign="center" mb={2}>
        {paymentStatusDetails?.icon}
      </Box>

      <Title variant="h4" textAlign="center">
        {paymentStatusDetails?.label}
      </Title>
      <Subtitle variant="subtitle1" textAlign="center">
        {paymentStatusDetails?.message}
      </Subtitle>

      {orderId && (
        <DetailBox>
          <Typography variant="body1">
            <strong>ID do Pedido:</strong> {orderId}
          </Typography>
        </DetailBox>
      )}

      {paymentInfo?.qrCodeImage && (
        <Box textAlign="center" mt={2}>
          <Typography variant="h6">Escaneie o QR Code Pix:</Typography>
          <img
            src={`${paymentInfo.qrCodeImage}`}
            alt="QR Code Pix"
            width="200"
            height="200"
            style={{
              width: "200px",
              height: "200px",
              objectFit: "contain",
              margin: "10px auto",
            }}
            loading="lazy"
          />
        </Box>
      )}

      {paymentInfo?.qrCode && (
        <Box textAlign="center" mt={2}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt={2}
          >
            {!isMobile && (
              <Typography variant="body2" sx={{ mr: 1 }}>
                <strong>Código Pix:</strong> {paymentInfo.qrCode}
              </Typography>
            )}
            <Tooltip title="Copiar Código Pix">
              <IconButton
                onClick={() => handleCopyToClipboard(paymentInfo.qrCode!)}
                color="primary"
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      )}

      {paymentInfo?.boletoUrl && (
        <Box textAlign="center" mt={3}>
          <Typography variant="h6">Baixe o boleto:</Typography>
          <Button
            variant="contained"
            color="primary"
            href={paymentInfo.boletoUrl}
            target="_blank"
            sx={{ mt: 1 }}
          >
            Visualizar Boleto
          </Button>
        </Box>
      )}

      <ActionGrid container spacing={2} sx={{ mt: 3 }}>
        <Grid item xs={12} sm={6}>
          <StyledButton
            variant="outlined"
            color="primary"
            fullWidth
            startIcon={<ShoppingCartIcon />}
            onClick={() => navigate("/pedidos")}
          >
            Ver Meus Pedidos
          </StyledButton>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledButton
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => navigate("/")}
          >
            Continuar Comprando
          </StyledButton>
        </Grid>
      </ActionGrid>

      <CustomSnackBar
        timeToClose={3000}
        approvalSnackbarOpen={snackbarOpen}
        handleCloseApprovalSnackbar={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
        >
          Código Pix copiado com sucesso!
        </Alert>
      </CustomSnackBar>

      <CustomSnackBar
        approvalSnackbarOpen={approvalSnackbarOpen}
        handleCloseApprovalSnackbar={handleCloseApprovalSnackbar}
      >
        <Alert
          onClose={handleCloseApprovalSnackbar}
          severity="success"
          variant="filled"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => navigate("/pedidos")}
            >
              Ver Meus Pedidos
            </Button>
          }
        >
          Pagamento aprovado! Você já pode acessar o produto.
        </Alert>
      </CustomSnackBar>
    </PaperStyled>
  );
};

export default Confirmation;
