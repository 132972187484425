import { FiltersState, Product } from "~/common/entities/product";
import baseApi from "~/common/services/base-api";

interface GetProductsResponse {
  response: {
    data: Product[];
    page: number;
    pageSize: number;
    total: number;
  };
}

export const getProducts = async (
  pageNumber: number,
  pageSize: number,
  filters?: FiltersState,
): Promise<GetProductsResponse | null> => {
  const { categoryId, priceRange, type, searchTerm } = filters || {};
  try {
    const response = await baseApi.get("/products", {
      params: {
        page: pageNumber,
        pageSize: pageSize,
        categoryIds: categoryId?.join(","),
        minPrice: priceRange?.[0],
        maxPrice: priceRange?.[1],
        isHandout: type?.isHandout,
        isRecordedCourse: type?.isRecordedCourse,
        searchTerm: searchTerm,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar os produtos", error);
    return null;
  }
};

export const getProductById = async (productId?: string) => {
  try {
    const response = await baseApi(`/products/${productId}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar o produto", error);
  }
};

export const getRelatedProducts = async (productId: string) => {
  try {
    const response = await baseApi(
      `/products/related/${productId}?page=1&pageSize=4`,
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar produtos relacionados", error);
  }
};
