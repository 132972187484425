import React from "react";

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  React.PropsWithChildren,
  ErrorBoundaryState
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error("Erro capturado no Error Boundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div>Desculpe, algo deu errado ao carregar este componente.</div>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
