import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import { Box, Grid, Typography } from "@mui/material";
import { BaseScreen } from "~/common/components/base-screen/base-screen";
import { FiltersState } from "~/common/entities/product";
import { useProducts } from "~/features/shop/hooks/use-products";
import {
  ContentContainer,
  PageContainer,
  PageTitle,
  SidebarContainer,
} from "~/features/shop/screens/page-layout.styles";
import { Filters } from "../filters/filters";
import { ProductCard } from "../product-card/product-card";
import { BaseLoader } from "~/common/components/base-loader/base-loader";
import { useIsMobile } from "~/common/hooks/use-is-mobile";

interface ProductsPageProps {
  initialFilters: FiltersState;
  categoryType: "PRODUTO" | "CURSO";
  pageTitle: string;
}

const ProductsPage = ({
  initialFilters,
  categoryType,
  pageTitle,
}: ProductsPageProps) => {
  const { products, categories, setFilters, loading, hasMore, lastProductRef } =
    useProducts({ initialFilters, categoryType });
  const isMobile = useIsMobile();

  return (
    <BaseScreen>
      <PageContainer>
        <SidebarContainer>
          <Filters setFilters={setFilters} categories={categories} />
        </SidebarContainer>
        <ContentContainer>
          <PageTitle variant={isMobile ? "h5" : "h4"}>{pageTitle}</PageTitle>
          {products.length === 0 && !loading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
                textAlign: "center",
                color: "text.secondary",
              }}
            >
              <SentimentDissatisfiedIcon sx={{ fontSize: 60, mb: 2 }} />
              <Typography variant="h6">
                Nenhum produto encontrado com os filtros aplicados.
              </Typography>
              <Typography variant="body1" sx={{ mt: 1 }}>
                Tente ajustar os filtros ou verificar mais tarde.
              </Typography>
            </Box>
          ) : (
            <>
              <Grid container spacing={3}>
                {products.map((product, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={product.id}
                    ref={products.length === index + 1 ? lastProductRef : null}
                  >
                    <ProductCard product={product} />
                  </Grid>
                ))}
              </Grid>
              {loading && <BaseLoader />}
              {!hasMore && products.length > 0 && (
                <Typography
                  variant="body2"
                  sx={{ textAlign: "center", mt: 2, color: "text.secondary" }}
                >
                  Todos os produtos foram carregados.
                </Typography>
              )}
            </>
          )}
        </ContentContainer>
      </PageContainer>
    </BaseScreen>
  );
};

export default ProductsPage;
