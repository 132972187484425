import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PasswordResetForm } from "../components/password-reset-form";
import baseApi from "~/common/services/base-api";
import { BaseScreen } from "~/common/components/base-screen/base-screen";
import { CustomSnackBar } from "~/common/components/custom-snackbar";

const LoggedInPasswordReset: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (
    currentPassword: string,
    newPassword: string,
    confirmPassword: string,
  ) => {
    setIsLoading(true);
    try {
      await baseApi.post("/user/change-password", {
        currentPassword,
        newPassword,
        confirmPassword,
      });
      setIsSuccess(true);
      setFeedbackMessage("Senha alterada com sucesso!");
      setTimeout(() => navigate("/perfil"), 2000);
    } catch (err: unknown) {
      const error = (err as any).response?.data;
      setIsError(true);
      setFeedbackMessage(
        "Erro ao alterar a senha: " + error?.message || "Erro desconhecido",
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BaseScreen>
      <Container component="main" sx={{ py: 4 }}>
        <Box sx={{ maxWidth: 600, mx: "auto" }}>
          <Card elevation={3}>
            <CardContent sx={{ p: 4 }}>
              <Typography variant="h5" component="h1" gutterBottom>
                Alterar Senha
              </Typography>
              <Typography color="text.secondary" mb={3}>
                Para alterar sua senha, digite sua senha atual e a nova senha
                desejada
              </Typography>

              <PasswordResetForm
                onSubmit={handleSubmit}
                isLoggedIn={true}
                isLoading={isLoading}
                isSuccess={isSuccess}
                isError={isError}
              />

              <Button
                variant="text"
                color="primary"
                sx={{
                  alignSelf: "flex-start",
                  "&:hover": {
                    backgroundColor: "transparent",
                    textDecoration: "underline",
                  },
                  ml: -1,
                }}
                onClick={() => navigate("/recuperar-senha/solicitar")}
              >
                Esqueceu sua senha atual?
              </Button>
            </CardContent>
          </Card>
        </Box>

        <CustomSnackBar
          approvalSnackbarOpen={isSuccess || isError}
          timeToClose={6000}
          handleCloseApprovalSnackbar={() => {
            setIsSuccess(false);
            setIsError(false);
          }}
        >
          <Alert severity={isSuccess ? "success" : "error"} variant="filled">
            {feedbackMessage}
          </Alert>
        </CustomSnackBar>
      </Container>
    </BaseScreen>
  );
};

export default LoggedInPasswordReset;
