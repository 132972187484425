import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSessionExpiredModalOpen: false,
  isAuthorizationErrorModalOpen: false,
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    sessionExpired(state) {
      state.isSessionExpiredModalOpen = true;
    },
    authorizationError(state) {
      state.isAuthorizationErrorModalOpen = true;
    },
    closeSessionExpiredModal(state) {
      state.isSessionExpiredModalOpen = false;
    },
    closeAuthorizationErrorModal(state) {
      state.isAuthorizationErrorModalOpen = false;
    },
  },
});

export const {
  sessionExpired,
  closeSessionExpiredModal,
  authorizationError,
  closeAuthorizationErrorModal,
} = sessionSlice.actions;
export default sessionSlice.reducer;
