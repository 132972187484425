import { styled } from "@mui/material/styles";
import faleConoscoBanner from "./image/fale-conosco-banner.webp";

export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "2rem 1rem",
  backgroundColor: "#f0f4f8",
  [theme.breakpoints.down("sm")]: {
    padding: "1rem 0.5rem",
  },
}));

export const Header = styled("header")(({ theme }) => ({
  textAlign: "center",
  backgroundImage: `url(${faleConoscoBanner})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  color: "white",
  padding: "4rem 1rem",
  width: "100%",
  borderRadius: "12px",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  position: "relative",
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    padding: "2rem 0.5rem",
  },
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    zIndex: 1,
  },
  "> *": {
    position: "relative",
    zIndex: 2,
  },
}));

export const Title = styled("h1")(({ theme }) => ({
  fontSize: "2.8rem",
  fontWeight: "bold",
  marginBottom: "1rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.8rem",
  },
}));

export const Subtitle = styled("p")(({ theme }) => ({
  fontSize: "1.2rem",
  marginBottom: "1rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

export const InfoContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1.5fr",
  gridTemplateRows: "auto auto",
  marginTop: "35px",
  gap: "1.5rem",
  width: "100%",
  maxWidth: "1200px",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto auto",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "20px",
    gap: "1rem",
  },
  "& > div:nth-child(1)": {
    gridColumn: "1 / 2",
    gridRow: "1 / 2",
    [theme.breakpoints.down("md")]: {
      gridColumn: "1 / 2",
      gridRow: "1 / 2",
    },
  },
  "& > div:nth-child(2)": {
    gridColumn: "1 / 2",
    gridRow: "2 / 3",
    [theme.breakpoints.down("md")]: {
      gridColumn: "1 / 2",
      gridRow: "2 / 3",
    },
  },
  "& > div:nth-child(3)": {
    gridColumn: "2 / 3",
    gridRow: "1 / 3",
    [theme.breakpoints.down("md")]: {
      gridColumn: "1 / 2",
      gridRow: "3 / 4",
    },
  },
}));

export const InfoBox = styled("div")(({ theme }) => ({
  flex: 1,
  background: "#ffffff",
  padding: "2rem",
  borderRadius: "12px",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  border: "2px solid #e4e9ef",
  [theme.breakpoints.down("sm")]: {
    padding: "1rem",
  },
}));

export const InfoItem = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  fontSize: "1rem",
  strong: {
    fontWeight: "bold",
  },
});

export const Icon = styled("span")({
  fontSize: "1.5rem",
  color: "#007bff",
});

export const MapContainer = styled("div")(({ theme }) => ({
  flex: 1,
  borderRadius: "12px",
  overflow: "hidden",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  height: "300px",
  border: "2px solid #e4e9ef",
  [theme.breakpoints.down("sm")]: {
    height: "200px",
  },
}));

export const FormContainer = styled("div")(({ theme }) => ({
  background: "#ffffff",
  padding: "2rem",
  borderRadius: "12px",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  width: "100%",
  maxWidth: "800px",
  [theme.breakpoints.down("sm")]: {
    padding: "1rem",
  },
}));

export const StyledInput = styled("input")(({ theme }) => ({
  width: "100%",
  padding: "0.8rem",
  margin: "0.5rem 0",
  border: "1px solid #ccc",
  borderRadius: "8px",
  fontSize: "1rem",
  [theme.breakpoints.down("sm")]: {
    padding: "0.6rem",
    fontSize: "0.9rem",
  },
}));

export const StyledTextArea = styled("textarea")(({ theme }) => ({
  width: "100%",
  padding: "0.8rem",
  margin: "0.5rem 0",
  border: "1px solid #ccc",
  borderRadius: "8px",
  fontSize: "1rem",
  resize: "vertical",
  [theme.breakpoints.down("sm")]: {
    padding: "0.6rem",
    fontSize: "0.9rem",
  },
}));

export const SubmitButton = styled("button")(({ theme }) => ({
  background: "#007bff",
  color: "white",
  border: "none",
  padding: "0.8rem 1.5rem",
  borderRadius: "8px",
  cursor: "pointer",
  fontSize: "1rem",
  marginTop: "1rem",
  transition: "background 0.3s",
  "&:hover": {
    background: "#0056b3",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0.6rem 1.2rem",
    fontSize: "0.9rem",
  },
}));
