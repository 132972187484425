import { decode } from "html-entities";

export const cleanUpText = (html: string): string => {
  if (!html) {
    return "";
  }

  const noHtml = html.replace(/<\/?[^>]+(>|$)/g, "");

  const decodedText = decode(noHtml);

  return decodedText;
};
