import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import { SearchWrapper, SearchField } from "./sidebar-searchbar.styles";
import { useState, useEffect, useRef } from "react";

interface SidebarSearchBarProps {
  onSearch: (value: string) => void;
  debounceTime?: number;
}

const SidebarSearchBar = ({
  onSearch,
  debounceTime = 500,
}: SidebarSearchBarProps) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const onSearchRef = useRef(onSearch);

  useEffect(() => {
    onSearchRef.current = onSearch;
  }, [onSearch]);

  useEffect(() => {
    if (searchTerm === "") {
      onSearchRef.current("");
      return;
    }

    const handler = setTimeout(() => {
      onSearchRef.current(searchTerm);
    }, debounceTime);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, debounceTime]);

  return (
    <SearchWrapper>
      <SearchField
        fullWidth
        placeholder="Pesquisar..."
        size="small"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ marginRight: 0.2 }}>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </SearchWrapper>
  );
};

export default SidebarSearchBar;
