import React from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Stack,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Mail as MailIcon,
  Lock as LockIcon,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsLoading } from "~/redux/slicers/applicationSlicer";
import { authenticate } from "~/features/login/services/auth";

interface Props {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  setIsSuccess: (value: boolean) => void;
  setIsError: (value: boolean) => void;
  setFeedbackMessage: (value: string) => void;
  isSuccess: boolean;
  isError: boolean;
}

export const LoginForm = ({
  isLoading,
  setIsLoading,
  setIsSuccess,
  setIsError,
  setFeedbackMessage,
  isSuccess,
  isError,
}: Props) => {
  const [userMail, setUserMail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [rememberMe, setRememberMe] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);

    setIsSuccess(false);
    setIsError(false);
    try {
      await authenticate({ email: userMail, password, rememberMe }, dispatch);
      setIsSuccess(true);
      navigate("/");
    } catch (error) {
      const err = error as {
        response: {
          data: {
            message: string;
          };
        };
      };
      setIsError(true);
      setFeedbackMessage(String(err.response?.data.message));
    } finally {
      setIsLoading(false);
    }
  }

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev);
  };

  function renderSubmitButtonContent() {
    if (isLoading) {
      return <CircularProgress size={24} color="inherit" />;
    }

    if (isSuccess) return <CheckCircleIcon />;

    if (isError) return <ErrorIcon />;

    return "Entrar";
  }

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate>
      <Stack spacing={3}>
        <TextField
          required
          fullWidth
          id="email"
          label="Endereço de e-mail"
          name="email"
          autoComplete="email"
          autoFocus
          value={userMail}
          onChange={(e) => setUserMail(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailIcon color="action" />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          required
          fullWidth
          name="password"
          label="Senha"
          type={showPassword ? "text" : "password"}
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon color="action" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleTogglePassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
              color="primary"
            />
          }
          label="Lembrar-me"
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          size="large"
          disabled={!userMail || !password || isLoading}
          sx={{
            py: 1.5,
            mt: 2,
            position: "relative",
            "& .MuiCircularProgress-root": {
              color: "inherit",
            },
          }}
        >
          {renderSubmitButtonContent()}
        </Button>
        <Box display="flex" justifyContent={"space-between"}>
          <Button
            variant="text"
            color="primary"
            sx={{
              alignSelf: "flex-start",
              "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
              },
            }}
            onClick={() => navigate("/recuperar-senha/solicitar")}
          >
            Esqueceu sua senha?
          </Button>

          <Button
            variant="text"
            color="primary"
            sx={{
              alignSelf: "flex-start",
              "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
              },
            }}
            onClick={() => navigate("/")}
          >
            Voltar a Página Inicial
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default LoginForm;
