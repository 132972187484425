import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import baseApi from "~/common/services/base-api";
import { StudentCourse } from "~/features/student-area/types/student-area";

interface StudentCoursesState {
  courses: StudentCourse[];
  loading: boolean;
  error: string | null;
}

const initialState: StudentCoursesState = {
  courses: [],
  loading: false,
  error: null,
};

interface FetchStudentCoursesResponse {
  response: StudentCourse[];
}

export const fetchStudentCourses = createAsyncThunk<
  StudentCourse[],
  void,
  { rejectValue: string }
>("studentCourses/fetchStudentCourses", async (_, { rejectWithValue }) => {
  try {
    const response =
      await baseApi.get<FetchStudentCoursesResponse>("/courses/student");
    return response.data.response;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    }
    return rejectWithValue("Erro ao buscar cursos do aluno.");
  }
});

const studentCoursesSlice = createSlice({
  name: "studentCourses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStudentCourses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchStudentCourses.fulfilled,
        (state, action: PayloadAction<StudentCourse[]>) => {
          state.loading = false;
          state.courses = action.payload;
        },
      )
      .addCase(
        fetchStudentCourses.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "Erro ao buscar cursos do aluno.";
        },
      );
  },
});

export default studentCoursesSlice.reducer;
