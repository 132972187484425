import { Helmet } from "react-helmet";
import { Product } from "~/common/entities/product";

interface ProductSchemaProps {
  product: Product;
}

const ProductSchema = ({ product }: ProductSchemaProps) => {
  if (!product) return null;

  const hasDiscount = (product.discount ?? 0) > 0;
  const hasLessons = product.totalLessonsCount && product.totalLessonsCount > 0;

  const itemType =
    product.type === "CURSO" || hasLessons ? "Course" : "Product";

  const productSchema: {
    "@context": string;
    "@type": string;
    name: string;
    description: string;
    image: string;
    url: string;
    sku: string;
    category: string;
    offers?: any;
    provider?: any;
    numberOfLessons?: number;
  } = {
    "@context": "https://schema.org",
    "@type": itemType,
    name: product.title,
    description: product.description || product.resumedDescription || "",
    image: product.imageUrl || "/placeholder.jpg",
    url: window.location.href,
    sku: `prod-${product.id}`,
    category: product?.category?.name || "",
  };

  if (product.price) {
    const priceData: {
      "@type": string;
      price: number | undefined;
      priceCurrency: string;
      availability: string;
      priceValidUntil?: string;
    } = {
      "@type": "Offer",
      price: hasDiscount ? product.discountedPrice : product.price,
      priceCurrency: "BRL",
      availability: "https://schema.org/InStock",
    };

    if (hasDiscount) {
      priceData.priceValidUntil = new Date(
        new Date().setFullYear(new Date().getFullYear() + 1),
      )
        .toISOString()
        .split("T")[0];
    }

    productSchema["offers"] = priceData;
  }

  if (itemType === "Course") {
    productSchema.provider = {
      "@type": "Organization",
      name: "CR Cursos Preparatórios",
      url: window.location.origin,
    };

    if (hasLessons) {
      productSchema.numberOfLessons = product.totalLessonsCount;
    }
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(productSchema)}
      </script>
    </Helmet>
  );
};

export default ProductSchema;
