import DownloadIcon from "@mui/icons-material/Download";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Button, CardContent, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BaseScreen } from "~/common/components/base-screen/base-screen";
import { getDownloads } from "../services/get-downloads";
import {
  ActionButton,
  CardHeader,
  Container,
  DownloadButtons,
  DownloadCard,
  DownloadsGrid,
  EmptyIcon,
  EmptyStateBox,
  HeaderAvatar,
  Title,
} from "./downloads.styles";
import { TimerIcon } from "lucide-react";
import moment from "moment";
import { Box } from "@mui/system";
import { cleanUpText } from "~/utils/clean-up-text";
import { BaseLoader } from "~/common/components/base-loader/base-loader";

interface Documento {
  id: string;
  title: string;
  url: string;
}

interface DownloadItem {
  id: string;
  titulo: string;
  descricaoResumida: string;
  endDate?: string;
  documentos: Documento[];
}

const DownloadsPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [downloads, setDownloads] = useState<DownloadItem[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDownloads = async () => {
      try {
        const response = await getDownloads();
        setDownloads(response);
      } catch (error) {
        console.error("Erro ao carregar downloads:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDownloads();
  }, []);

  return (
    <BaseScreen>
      {loading ? (
        <BaseLoader />
      ) : (
        <Container>
          <Title variant="h4">📂 Meus Downloads</Title>
          {downloads.length > 0 ? (
            <DownloadsGrid container spacing={4}>
              {downloads.map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item.id}>
                  <DownloadCard>
                    <CardContent>
                      <CardHeader>
                        <HeaderAvatar>
                          <FolderOpenIcon
                            sx={{ color: "#ffffff", fontSize: 30 }}
                          />
                        </HeaderAvatar>
                        <Typography
                          variant="h6"
                          color="#1976d2"
                          fontWeight="bold"
                        >
                          {item.titulo}
                        </Typography>
                      </CardHeader>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                        sx={{ textAlign: "center" }}
                      >
                        {cleanUpText(item.descricaoResumida ?? "")}
                      </Typography>
                      <Divider sx={{ my: 2 }} />
                      {item.endDate && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 1,
                            mb: 2,
                          }}
                        >
                          <TimerIcon
                            style={{
                              fontSize: 16,
                              color: "text.secondary",
                            }}
                          />
                          <Typography variant="body2" color="text.secondary">
                            Acesso até:{" "}
                            {moment
                              .utc(item.endDate)
                              .local()
                              .format("DD/MM/YYYY - HH:mm")}
                          </Typography>
                        </Box>
                      )}
                      <DownloadButtons>
                        {item.documentos.map((doc) => (
                          <Button
                            key={doc.id}
                            href={doc.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="outlined"
                            color="primary"
                            startIcon={<DownloadIcon />}
                            sx={{
                              textTransform: "none",
                              borderRadius: "20px",
                              px: 3,
                              py: 1.5,
                              fontWeight: "bold",
                              boxShadow: 1,
                              "&:hover": {
                                boxShadow: 3,
                              },
                            }}
                          >
                            {doc.title}
                          </Button>
                        ))}
                      </DownloadButtons>
                    </CardContent>
                  </DownloadCard>
                </Grid>
              ))}
            </DownloadsGrid>
          ) : (
            <EmptyStateBox>
              <EmptyIcon />
              <Typography variant="h5" gutterBottom>
                Nenhum Download Encontrado
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Parece que você ainda não possui nenhum material para download.
                Realize uma compra para acessar seus conteúdos!
              </Typography>
              <ActionButton
                variant="contained"
                color="primary"
                startIcon={<ShoppingCartIcon />}
                onClick={() => navigate("/")}
                sx={{ mt: 2 }}
              >
                Continuar Comprando
              </ActionButton>
            </EmptyStateBox>
          )}
        </Container>
      )}
    </BaseScreen>
  );
};

export default DownloadsPage;
