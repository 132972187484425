import { FiltersState } from "~/common/entities/product";
import ProductsPage from "~/features/shop/components/list-products/list-products";

const HandoutsPage = () => {
  const initialFilters: FiltersState = {
    categoryId: [],
    type: {
      isHandout: true,
      isRecordedCourse: false,
    },
    priceRange: [0, 550],
  };

  return (
    <ProductsPage
      initialFilters={initialFilters}
      categoryType="PRODUTO"
      pageTitle="Material Apostilado"
    />
  );
};

export default HandoutsPage;
