import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import SchoolIcon from "@mui/icons-material/School";
import {
  Alert,
  alpha,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Container,
  Typography,
  LinearProgress,
  Stack,
  useTheme,
} from "@mui/material";
import { Timer, Video } from "lucide-react";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BaseScreen } from "~/common/components/base-screen/base-screen";
import { DocumentMenu } from "~/features/student-area/components/document-menu/document-menu";
import { StudentCourse } from "~/features/student-area/types/student-area";
import { fetchStudentCourses } from "~/redux/slicers/studentCoursesSlicer";
import { AppDispatch, ApplicationState } from "~/redux/store";

const StudentArea: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { courses, loading, error } = useSelector(
    (state: ApplicationState) => state.studentCourses,
  );

  useEffect(() => {
    dispatch(fetchStudentCourses());
  }, [dispatch]);

  const EmptyState = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pb: 6,
      }}
    >
      <Box
        sx={{
          width: 80,
          height: 80,
          borderRadius: "50%",
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: 3,
        }}
      >
        <SchoolIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />
      </Box>
      <Typography variant="h5" gutterBottom>
        Você ainda não possui cursos adquiridos
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        align="center"
        sx={{ mb: 4 }}
      >
        Que tal começar sua jornada de aprendizado agora mesmo?
      </Typography>
      <Button
        variant="contained"
        size="large"
        startIcon={<SchoolIcon />}
        onClick={() => navigate("/cursos-gravados")}
        sx={{ borderRadius: 2, px: 4, py: 1.5 }}
      >
        Explorar Cursos
      </Button>
    </Box>
  );

  return (
    <BaseScreen>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Box sx={{ textAlign: "center", mb: 6 }}>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            Área do Aluno
          </Typography>
          <Typography variant="h6" color="text.secondary">
            Continue sua jornada de aprendizado
          </Typography>
        </Box>
        {loading && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <CircularProgress size={60} />
          </Box>
        )}
        {error && (
          <Alert severity="error" sx={{ mt: 4 }}>
            {error}
          </Alert>
        )}
        {!loading && !error && (
          <>
            {courses.length > 0 ? (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(3, 1fr)",
                  },
                  gap: 3,
                }}
              >
                {courses.map((course: StudentCourse) => (
                  <Card
                    key={course.id}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: 2,
                      boxShadow: 3,
                      transition: "transform 0.3s, box-shadow 0.3s",
                      maxWidth: 368,
                      "&:hover": {
                        transform: "translateY(-8px)",
                        boxShadow: 6,
                      },
                    }}
                  >
                    {course.product.image && (
                      <Box sx={{ position: "relative" }}>
                        <CardMedia
                          component="img"
                          height="200"
                          image={course.product.image}
                          alt={course.title}
                          sx={{
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                          }}
                          loading="lazy"
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: "40%",
                            background:
                              "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)",
                            borderBottomLeftRadius: 8,
                            borderBottomRightRadius: 8,
                          }}
                        />
                      </Box>
                    )}
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                        <Chip
                          icon={<MenuBookIcon />}
                          label={`${course.modules?.length || 0} módulo${course.modules?.length === 1 ? "" : "s"}`}
                          size="small"
                          color="primary"
                        />
                        <Chip
                          icon={
                            <Video
                              size={16}
                              color={theme.palette.common.white}
                            />
                          }
                          label={`${course.modules?.reduce((acc, module) => acc + module.lessons.length, 0) || 0} aulas`}
                          size="small"
                          color="secondary"
                        />
                      </Stack>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        sx={{ fontWeight: "bold" }}
                      >
                        {course.title}
                      </Typography>
                      {course.endDate && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            mt: 1,
                          }}
                        >
                          <Timer size={16} color={theme.palette.primary.main} />
                          <Typography variant="body2" color="text.secondary">
                            Acesso até:{" "}
                            {moment
                              .utc(course.endDate)
                              .local()
                              .format("DD/MM/YYYY - HH:mm")}
                          </Typography>
                        </Box>
                      )}
                      {course.progress !== undefined && (
                        <Box sx={{ mt: 3 }}>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ mb: 1 }}
                          >
                            Progresso do curso: {course.progress}%
                          </Typography>
                          <LinearProgress
                            variant="determinate"
                            value={course.progress}
                            sx={{
                              height: 8,
                              borderRadius: 4,
                              "& .MuiLinearProgress-bar": { borderRadius: 4 },
                            }}
                          />
                        </Box>
                      )}
                    </CardContent>
                    <CardActions sx={{ p: 2, pt: 0 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<PlayCircleOutlineIcon />}
                        endIcon={<ArrowForwardIcon />}
                        onClick={() => navigate(`/aluno/cursos/${course.id}`)}
                        sx={{
                          flexGrow: 1,
                          textTransform: "none",
                          borderRadius: 2,
                          px: 3,
                          py: 1,
                        }}
                      >
                        Continuar
                      </Button>
                      {course.product.documents &&
                        course.product.documents.length > 0 && (
                          <DocumentMenu documents={course.product.documents} />
                        )}
                    </CardActions>
                  </Card>
                ))}
              </Box>
            ) : (
              <EmptyState />
            )}
          </>
        )}
      </Container>
    </BaseScreen>
  );
};

export default StudentArea;
