import { useEffect, useState, useCallback } from "react";
import { CircularProgress, Container, Typography } from "@mui/material";
import { Photo } from "./photos.types";
import { fetchPhotos } from "../services/get-photos";
import {
  PhotoGrid,
  PhotoCard,
  PhotoTitle,
  PhotoOverlay,
} from "./photos.styles";
import { BaseScreen } from "~/common/components/base-screen/base-screen";
import { BaseLoader } from "~/common/components/base-loader/base-loader";

const PhotosPage = () => {
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [loading, setLoading] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const isEmpty = !photos.length;
  const pageSize = 9;

  const loadPhotos = useCallback(async (page: number) => {
    try {
      setIsFetchingMore(true);
      const response = await fetchPhotos(page, pageSize);
      const newPhotos = response.response.data;
      const total = response.response.total;

      if (page === 1) {
        setPhotos(newPhotos);
      } else {
        setPhotos((prev) => [...prev, ...newPhotos]);
      }
      setHasMore(page * pageSize < total);
      setLoading(false);
      setIsFetchingMore(false);
    } catch (error) {
      console.error("Erro ao buscar fotos:", error);
    }
  }, []);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 200 &&
      !isFetchingMore &&
      hasMore
    ) {
      setCurrentPage((prev) => prev + 1);
    }
  }, [isFetchingMore, hasMore]);

  useEffect(() => {
    loadPhotos(currentPage);
  }, [currentPage, loadPhotos]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const renderContent = () => {
    if (loading) {
      return <BaseLoader />;
    }

    if (isEmpty) {
      return (
        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            mt: 5,
            color: "text.secondary",
            height: "calc(40vh - 64px)",
          }}
        >
          Nenhuma foto encontrada.
        </Typography>
      );
    }

    return (
      <Container sx={{ mt: 5, mb: 10 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            mb: 3,
            color: "primary.main",
          }}
        >
          Galeria de Fotos
        </Typography>
        <PhotoGrid>
          {photos.map((photo) => (
            <PhotoCard key={photo.id}>
              <img
                src={photo.imageUrl || ""}
                alt={photo.title}
                width="300"
                height="300"
                style={{
                  width: "100%",
                  height: "300px",
                  objectFit: "cover",
                }}
                loading="lazy"
              />
              <PhotoOverlay>
                <PhotoTitle>{photo.title}</PhotoTitle>
              </PhotoOverlay>
            </PhotoCard>
          ))}
        </PhotoGrid>
        {isFetchingMore && (
          <Container sx={{ textAlign: "center", mt: 3, flex: 1 }}>
            <CircularProgress />
          </Container>
        )}
        {!isFetchingMore && !isEmpty && !hasMore && !loading && (
          <Typography
            variant="body2"
            sx={{ textAlign: "center", mt: 5, color: "text.secondary" }}
          >
            Você chegou ao final da galeria.
          </Typography>
        )}
      </Container>
    );
  };

  return <BaseScreen>{renderContent()}</BaseScreen>;
};

export default PhotosPage;
