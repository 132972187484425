import { ArrowForward } from "@mui/icons-material";
import {
  Alert,
  Box,
  Card,
  CardContent,
  Container,
  Link,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import logo_azul from "~/common/assets/logo_azul.webp";
import Footer from "~/common/components/footer/footer";
import { FirstHeaderSession } from "~/common/components/header/first-header-session/first-header-session";
import { LoginForm } from "./components/login-form/login-form";
import { useNavigate } from "react-router-dom";
import { CustomSnackBar } from "~/common/components/custom-snackbar";

const SignIn = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [feedbackMessage, setFeedbackMessage] = React.useState("");

  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCloseSnackbar = () => {
    setIsError(false);
    setIsSuccess(false);
    setFeedbackMessage("");
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        bgcolor: "grey.50",
      }}
    >
      <Box
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          boxShadow: 1,
        }}
      >
        <FirstHeaderSession />
      </Box>

      <Container component="main" sx={{ flex: 1, py: 4 }}>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card
            elevation={2}
            sx={{
              width: "100%",
              maxWidth: 720,
              borderRadius: 2,
              bgcolor: "background.paper",
            }}
          >
            <CardContent sx={{ p: { xs: 3, sm: 6 } }}>
              {" "}
              <Stack spacing={4} alignItems="center">
                <Box
                  component="img"
                  src={logo_azul}
                  alt="Logo"
                  sx={{
                    width: 96,
                    height: 96,
                    objectFit: "contain",
                  }}
                  loading="lazy"
                />

                <Stack spacing={1} alignItems="center">
                  <Typography
                    variant={isSmallScreen ? "h5" : "h4"}
                    component="h1"
                    fontWeight="bold"
                    color="text.primary"
                  >
                    Bem-vindo de volta
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    Entre com sua conta ou cadastre-se para começar
                  </Typography>
                </Stack>

                <Box sx={{ width: "100%", maxWidth: 560 }}>
                  {" "}
                  <LoginForm
                    isError={isError}
                    isLoading={isLoading}
                    isSuccess={isSuccess}
                    setFeedbackMessage={setFeedbackMessage}
                    setIsError={setIsError}
                    setIsLoading={setIsLoading}
                    setIsSuccess={setIsSuccess}
                  />
                </Box>

                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="body2" color="text.secondary">
                    Não tem uma conta?
                  </Typography>
                  <Link
                    onClick={() => navigate("/cadastro")}
                    underline="hover"
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      gap: 0.5,
                      color: "primary.main",
                      fontWeight: 500,
                      "&:hover": {
                        color: "primary.dark",
                      },
                    }}
                  >
                    Cadastre-se
                    <ArrowForward sx={{ fontSize: 16 }} />
                  </Link>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Box>
      </Container>

      <Footer />

      <CustomSnackBar
        approvalSnackbarOpen={isError || isSuccess}
        timeToClose={6000}
        handleCloseApprovalSnackbar={handleCloseSnackbar}
      >
        <Alert
          severity={isError ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {feedbackMessage}
        </Alert>
      </CustomSnackBar>
    </Box>
  );
};

export default SignIn;
