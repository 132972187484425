import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const PageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(4),
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  paddingLeft: theme.spacing(12),
  paddingRight: theme.spacing(12),
  [theme.breakpoints.only("md")]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  [theme.breakpoints.down("md")]: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const SidebarContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  marginRight: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    marginRight: 0,
  },
}));

export const ContentContainer = styled(Box)({
  flex: 4.5,
});

export const PageTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  textAlign: "left",
  fontWeight: "bold",
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
}));
