import React from "react";
import {
  Box,
  Typography,
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import {
  PictureAsPdf as PictureAsPdfIcon,
  Description as DescriptionIcon,
} from "@mui/icons-material";
import { CheckCircle, PlayCircle } from "lucide-react";
import ReactPlayer from "react-player";
import { Stack } from "@mui/system";

interface LessonViewProps {
  lesson: any;
  onComplete: (lessonId: number, completed: boolean) => void;
  supportMaterials?: any[];
}

export const LessonView: React.FC<LessonViewProps> = ({
  lesson,
  onComplete,
  supportMaterials,
}) => {
  if (!lesson) {
    return (
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Typography variant="h6">Selecione uma aula para começar</Typography>
      </Box>
    );
  }

  return (
    <Paper sx={{ p: 3, borderRadius: 2, boxShadow: 3 }}>
      <Stack spacing={2}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
            {lesson.title}
          </Typography>
          <Button
            variant={lesson.completed ? "contained" : "outlined"}
            color={lesson.completed ? "success" : "primary"}
            startIcon={
              lesson.completed ? (
                <CheckCircle size={20} />
              ) : (
                <PlayCircle size={20} />
              )
            }
            onClick={() => onComplete(lesson.id, !lesson.completed)}
            sx={{
              textTransform: "none",
              fontWeight: "medium",
              borderRadius: 2,
              px: 3,
              py: 1,
              minWidth: 150,
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: lesson.completed
                  ? "success.dark"
                  : "primary.dark",
                color: "#fff",
              },
            }}
          >
            {lesson.completed ? "Aula Concluída" : "Concluir Aula"}
          </Button>
        </Box>

        <Box sx={{ position: "relative", paddingTop: "56.25%" }}>
          <ReactPlayer
            url={lesson.videoUrl}
            controls
            width="100%"
            height="100%"
            style={{ position: "absolute", top: 0, left: 0 }}
          />
        </Box>

        {supportMaterials && supportMaterials.length > 0 && (
          <Box>
            <Typography variant="h6" gutterBottom>
              Materiais de Apoio
            </Typography>
            <List>
              {supportMaterials.map((doc, index) => (
                <ListItemButton
                  key={index}
                  component="a"
                  href={doc.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ pl: 0 }}
                >
                  <ListItemIcon>
                    {doc.url.toLowerCase().endsWith(".pdf") ? (
                      <PictureAsPdfIcon color="action" />
                    ) : (
                      <DescriptionIcon color="action" />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={doc.title} />
                </ListItemButton>
              ))}
            </List>
          </Box>
        )}
      </Stack>
    </Paper>
  );
};
