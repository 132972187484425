import baseApi from "~/common/services/base-api";

export const getNews = async (pageNumber: number, pageSize: number) => {
  try {
    const response = await baseApi(
      "/news?page=" + pageNumber + "&pageSize=" + pageSize,
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar as notícias", error);
  }
};

export const getNewById = async (id: number) => {
  try {
    const response = await baseApi("/news/" + id);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar a notícia", error);
  }
};
