import { styled } from "@mui/material/styles";
import { Box, Typography, Paper, Alert, Stepper } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(2, 4),
  pb: theme.spacing(4),
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

export const StepperStyled = styled(Stepper)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

export const PaperStyled = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  marginTop: theme.spacing(4),
}));

export const ErrorAlertStyled = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
