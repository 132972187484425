import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ApplicationState {
  isLoading: boolean;
}

const initialState: ApplicationState = {
  isLoading: false,
};

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setIsLoading } = applicationSlice.actions;
export default applicationSlice.reducer;
