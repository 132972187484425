import baseApi from "~/common/services/base-api";
import { loginSuccess } from "~/redux/slicers/authSlicer";
import { AppDispatch } from "~/redux/store";

interface LoginProps {
  email: string;
  password: string;
  rememberMe: boolean;
}

export const authenticate = async (
  { email, password, rememberMe }: LoginProps,
  dispatch: AppDispatch,
): Promise<void> => {
  const response = await baseApi.post("auth/login", {
    email,
    senha: password,
  });

  const {
    token,
    email: userEmail,
    name,
    userId,
    role,
  } = response.data.response;

  dispatch(
    loginSuccess({
      token,
      user: { email: userEmail, name, userId, role },
    }),
  );

  if (rememberMe) {
    localStorage.setItem("token", token);
  } else {
    sessionStorage.setItem("token", token);
  }
};
