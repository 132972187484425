import { styled, Paper, TextField, alpha } from "@mui/material";

export const SearchWrapper = styled(Paper)(({ theme }) => ({
  position: "relative",
  marginBottom: theme.spacing(3),
  overflow: "hidden",
  backgroundColor: alpha(theme.palette.background.paper, 0.5),
  borderRadius: theme.spacing(1),
  boxShadow: "none",
}));

export const SearchField = styled(TextField)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.spacing(2),
  "& .MuiInputBase-root": {
    borderRadius: theme.spacing(1),
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    border: "1px solid",
    borderColor: alpha(theme.palette.primary.main, 0.15),
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    transition: "background-color 0.3s ease, border-color 0.3s ease",
    "&:hover, &.Mui-focused": {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      borderColor: alpha(theme.palette.primary.main, 0.35),
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& input": {
      fontWeight: 500,
      fontSize: "0.95rem",
      padding: theme.spacing(1),
    },
  },
}));
