import { useParams } from "react-router-dom";
import { getCourseById } from "~/features/courses-face-to-face/services/get-courses";
import GenericDetailPage from "~/common/components/generic-detail-page/generic-detail-page";
import {
  ImageContainer,
  StyledImage,
  ContentBox,
  ContentText,
} from "./courses-details.styles";
import { Courses } from "~/features/courses-face-to-face/types/courses-face-to-face";
import { formatAndSanitizeHTML } from "~/utils/format-and-sanitize-html";
import { BaseScreen } from "~/common/components/base-screen/base-screen";

const CourseDetailPage = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <BaseScreen>
      <GenericDetailPage<Courses>
        id={Number(id)}
        fetchItem={getCourseById}
        renderContent={(course) => {
          return (
            <>
              <ImageContainer>
                <StyledImage
                  src={course.imageUrl || "/placeholder-image.jpg"}
                  alt={course.title}
                  loading="lazy"
                />
              </ImageContainer>
              <ContentBox>
                <ContentText
                  sx={{ whiteSpace: "pre-wrap" }}
                  dangerouslySetInnerHTML={formatAndSanitizeHTML(
                    course.description ?? "",
                  )}
                />
              </ContentBox>
            </>
          );
        }}
        enableSharing={true}
        getTitle={(course) => course.title}
        getSubtitle={(course) =>
          course.startDate
            ? `Início: ${
                course.startDate
                  ? new Date(course.startDate).toLocaleDateString("pt-BR")
                  : "Data não definida"
              }`
            : ""
        }
      />
    </BaseScreen>
  );
};

export default CourseDetailPage;
