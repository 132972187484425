import { Close, Edit, Password, Save } from "@mui/icons-material";
import {
  Alert,
  Box,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  ActionButtonsContainer,
  AddressSectionTitle,
  AvatarContainer,
  AvatarWithRing,
  ColoredDivider,
  FilledButton,
  GradientTitle,
  OutlinedButton,
  StyledContainer,
  StyledPaper,
} from "./user-profile.styles";

import { BaseScreen } from "~/common/components/base-screen/base-screen";
import baseApi from "~/common/services/base-api";
import { Role } from "~/common/types/user-session";
import { updateUser } from "~/redux/slicers/authSlicer";
import { AppDispatch } from "~/redux/store";
import { formatCPF, formatDate, formatPhone } from "~/utils/format";
import { niveisEscolares } from "~/utils/niveis-escolares";
import { BaseLoader } from "~/common/components/base-loader/base-loader";
import { CustomSnackBar } from "~/common/components/custom-snackbar";

interface UserData {
  id: number;
  cpf: string;
  nome: string;
  dataNascimento: string;
  endereco: string;
  cep: string;
  cidade: string;
  uf: string;
  numResidencia: number;
  numTelefone: string;
  email: string;
  nivelEscolar: string;
  tipoUsuario: string;
}

const UserProfilePage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [user, setUser] = useState<UserData | null>(null);
  const [isEditing, setIsEditing] = useState(false);

  const [formData, setFormData] = useState({
    cpf: "",
    nome: "",
    dataNascimento: "",
    numTelefone: "",
    email: "",
    nivelEscolar: "",
    cep: "",
    numResidencia: 0,
    endereco: "",
    cidade: "",
    uf: "",
    tipoUsuario: "",
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (
    message: string,
    severity: "success" | "error" | "warning" | "info",
  ) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await baseApi.get<UserData>("/user");
        setUser(response.data);

        setFormData({
          cpf: response.data.cpf,
          nome: response.data.nome,
          dataNascimento: response.data.dataNascimento,
          numTelefone: response.data.numTelefone,
          email: response.data.email,
          nivelEscolar: response.data.nivelEscolar,
          cep: response.data.cep,
          numResidencia: response.data.numResidencia || 0,
          endereco: response.data.endereco,
          cidade: response.data.cidade,
          uf: response.data.uf,
          tipoUsuario: response.data.tipoUsuario,
        });
      } catch (error) {
        console.error("Erro ao buscar dados do usuário:", error);
        showSnackbar("Falha ao buscar dados do usuário", "error");
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleNivelEscolarChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value;
    setFormData((prev) => ({
      ...prev,
      nivelEscolar: value,
    }));
  };

  const handleCepBlur = async () => {
    if (!formData.cep) return;

    try {
      const viaCepResponse = await fetch(
        `https://viacep.com.br/ws/${formData.cep}/json/`,
      );
      const data = await viaCepResponse.json();
      if (!data.erro) {
        setFormData((prev) => ({
          ...prev,
          endereco: data.logradouro || "",
          cidade: data.localidade || "",
          uf: data.uf || "",
        }));
      }
    } catch (error) {
      console.error("Falha ao buscar CEP:", error);
      showSnackbar("Não foi possível obter dados do CEP", "error");
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    if (!user) return;

    setFormData({
      cpf: user.cpf,
      nome: user.nome,
      dataNascimento: user.dataNascimento,
      numTelefone: user.numTelefone,
      email: user.email,
      nivelEscolar: user.nivelEscolar,
      cep: user.cep,
      numResidencia: user.numResidencia,
      endereco: user.endereco,
      cidade: user.cidade,
      uf: user.uf,
      tipoUsuario: user.tipoUsuario,
    });
    setIsEditing(false);
  };

  const handleSaveClick = async () => {
    if (!user) return;
    try {
      const updatedData = {
        nome: formData.nome,
        dataNascimento: formData.dataNascimento,
        numTelefone: formData.numTelefone,
        email: formData.email,
        nivelEscolar: formData.nivelEscolar,
        tipoUsuario: formData.tipoUsuario,
        cep: formData.cep,
        numResidencia: formData.numResidencia,
        endereco: formData.endereco,
        cidade: formData.cidade,
        uf: formData.uf,
      };

      await baseApi.put("/user/edit", updatedData);

      setUser((prev) =>
        prev
          ? {
              ...prev,
              nome: formData.nome,
              dataNascimento: formData.dataNascimento,
              numTelefone: formData.numTelefone,
              email: formData.email,
              nivelEscolar: formData.nivelEscolar,
              tipoUsuario: formData.tipoUsuario,
              cep: formData.cep,
              numResidencia: formData.numResidencia,
              endereco: formData.endereco,
              cidade: formData.cidade,
              uf: formData.uf,
            }
          : null,
      );

      dispatch(
        updateUser({
          email: updatedData.email,
          name: updatedData.nome,
          userId: user.id,
          role: updatedData.tipoUsuario as Role,
        }),
      );

      setIsEditing(false);
      showSnackbar("Informações atualizadas com sucesso!", "success");
    } catch (error) {
      console.error("Erro ao atualizar dados do usuário:", error);
      showSnackbar("Falha ao atualizar dados do usuário", "error");
    }
  };

  if (!user) {
    return (
      <BaseScreen>
        <BaseLoader />
      </BaseScreen>
    );
  }

  return (
    <BaseScreen>
      <StyledContainer>
        <GradientTitle variant="h4">Minhas Informações</GradientTitle>

        <StyledPaper elevation={3}>
          <AvatarContainer>
            <AvatarWithRing alt={user.nome}>{user.nome?.[0]}</AvatarWithRing>
            <Box>
              <Typography variant="subtitle2" color="text.secondary">
                Nome
              </Typography>
              {isEditing ? (
                <TextField
                  name="nome"
                  size="small"
                  fullWidth
                  value={formData.nome}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="h5" fontWeight="500">
                  {user.nome}
                </Typography>
              )}

              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Usuário: {user.tipoUsuario}
              </Typography>
            </Box>
          </AvatarContainer>

          <ColoredDivider />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="text.secondary">
                CPF
              </Typography>
              {isEditing ? (
                <TextField
                  name="cpf"
                  size="small"
                  fullWidth
                  value={formatCPF(formData.cpf)}
                  onChange={handleChange}
                  disabled
                  sx={{ mb: 2 }}
                />
              ) : (
                <Typography variant="body1" mb={2}>
                  {formatCPF(user.cpf)}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="text.secondary">
                Data de Nascimento
              </Typography>
              {isEditing ? (
                <TextField
                  name="dataNascimento"
                  size="small"
                  fullWidth
                  type="date"
                  value={formData.dataNascimento.split("T")[0]}
                  onChange={handleChange}
                  sx={{ mb: 2 }}
                />
              ) : (
                <Typography variant="body1" mb={2}>
                  {formatDate(user.dataNascimento)}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="text.secondary">
                Telefone
              </Typography>
              {isEditing ? (
                <TextField
                  name="numTelefone"
                  size="small"
                  fullWidth
                  value={formData.numTelefone}
                  onChange={handleChange}
                  sx={{ mb: 2 }}
                />
              ) : (
                <Typography variant="body1" mb={2}>
                  {formatPhone(user.numTelefone)}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="text.secondary">
                E-mail
              </Typography>
              {isEditing ? (
                <TextField
                  name="email"
                  size="small"
                  fullWidth
                  value={formData.email}
                  onChange={handleChange}
                  sx={{ mb: 2 }}
                />
              ) : (
                <Typography variant="body1" mb={2}>
                  {user.email}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="text.secondary">
                Nível Escolar
              </Typography>
              {isEditing ? (
                <Select
                  size="small"
                  fullWidth
                  name="nivelEscolar"
                  value={formData.nivelEscolar}
                  onChange={handleNivelEscolarChange}
                  sx={{ mb: 2, mt: 1 }}
                >
                  {niveisEscolares.map((nivel) => (
                    <MenuItem key={nivel} value={nivel}>
                      {nivel}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Typography variant="body1" mb={2}>
                  {user.nivelEscolar || "Não informado"}
                </Typography>
              )}
            </Grid>
          </Grid>

          <ColoredDivider />

          <AddressSectionTitle variant="h6" gutterBottom>
            Endereço
          </AddressSectionTitle>

          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle2" color="text.secondary">
                Rua
              </Typography>
              {isEditing ? (
                <TextField
                  name="endereco"
                  size="small"
                  fullWidth
                  disabled
                  value={formData.endereco}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1" mb={2}>
                  {user.endereco}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" color="text.secondary">
                Número
              </Typography>
              {isEditing ? (
                <TextField
                  name="numResidencia"
                  size="small"
                  fullWidth
                  value={formData.numResidencia}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1" mb={2}>
                  {user.numResidencia}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" color="text.secondary">
                CEP
              </Typography>
              {isEditing ? (
                <TextField
                  name="cep"
                  size="small"
                  fullWidth
                  value={formData.cep}
                  onChange={handleChange}
                  onBlur={handleCepBlur}
                />
              ) : (
                <Typography variant="body1" mb={2}>
                  {user.cep}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" color="text.secondary">
                Cidade
              </Typography>
              {isEditing ? (
                <TextField
                  name="cidade"
                  size="small"
                  fullWidth
                  value={formData.cidade}
                  onChange={handleChange}
                  disabled
                />
              ) : (
                <Typography variant="body1" mb={2}>
                  {user.cidade}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" color="text.secondary">
                UF
              </Typography>
              {isEditing ? (
                <TextField
                  name="uf"
                  size="small"
                  fullWidth
                  value={formData.uf}
                  onChange={handleChange}
                  disabled
                />
              ) : (
                <Typography variant="body1" mb={2}>
                  {user.uf}
                </Typography>
              )}
            </Grid>
          </Grid>

          <ColoredDivider />

          <ActionButtonsContainer>
            <FilledButton
              variant="contained"
              startIcon={<Password />}
              onClick={() => navigate("/redefinir-senha")}
            >
              Alterar Senha de Acesso
            </FilledButton>

            <OutlinedButton variant="outlined" onClick={() => navigate("/")}>
              Voltar
            </OutlinedButton>

            {!isEditing && (
              <FilledButton
                variant="contained"
                startIcon={<Edit />}
                onClick={handleEditClick}
              >
                Editar
              </FilledButton>
            )}

            {isEditing && (
              <>
                <FilledButton
                  variant="contained"
                  startIcon={<Save />}
                  onClick={handleSaveClick}
                >
                  Salvar
                </FilledButton>
                <OutlinedButton
                  variant="outlined"
                  color="error"
                  startIcon={<Close />}
                  onClick={handleCancelClick}
                >
                  Cancelar
                </OutlinedButton>
              </>
            )}
          </ActionButtonsContainer>
        </StyledPaper>
      </StyledContainer>

      <CustomSnackBar
        approvalSnackbarOpen={snackbarOpen}
        handleCloseApprovalSnackbar={handleSnackbarClose}
        timeToClose={4000}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </CustomSnackBar>
    </BaseScreen>
  );
};

export default UserProfilePage;
