import { useEffect, useState, useRef, useCallback } from "react";
import { getProducts } from "../services/get-products";
import { getCategories } from "../services/get-categories";
import { FiltersState, Product } from "~/common/entities/product";
import { Category } from "~/common/entities/category";

interface UseProductsProps {
  initialFilters: FiltersState;
  categoryType: "PRODUTO" | "CURSO";
}

interface UseProductsReturn {
  products: Product[];
  categories: Category[];
  filters: FiltersState;
  setFilters: React.Dispatch<React.SetStateAction<FiltersState>>;
  loading: boolean;
  hasMore: boolean;
  lastProductRef: (node: HTMLDivElement | null) => void;
  resetProducts: () => void;
}

export const useProducts = ({
  initialFilters,
  categoryType,
}: UseProductsProps): UseProductsReturn => {
  const [products, setProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [filters, setFilters] = useState<FiltersState>(initialFilters);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesResult = await getCategories(1, 20, filters.type);
        if (categoriesResult && categoriesResult.response) {
          const filteredCategories = categoriesResult.response.data.filter(
            (category: Category) => category.categoryType === categoryType,
          );
          setCategories(filteredCategories);
        }
      } catch (error) {
        console.error("Erro ao buscar categorias:", error);
      }
    };

    fetchCategories();
  }, [filters.type, categoryType]);

  useEffect(() => {
    setProducts([]);
    setPage(1);
    setHasMore(true);
  }, [filters]);

  useEffect(() => {
    let cancelRequest = false;

    const fetchProducts = async () => {
      setLoading(true);
      try {
        const productsResult = await getProducts(page, 20, filters);
        if (productsResult && productsResult.response) {
          const { data: newProducts, total } = productsResult.response;

          if (!cancelRequest) {
            setProducts((prevProducts) => {
              const uniqueProducts = [
                ...prevProducts,
                ...newProducts.filter(
                  (newProduct: Product) =>
                    !prevProducts.some(
                      (prevProduct) => prevProduct.id === newProduct.id,
                    ),
                ),
              ];
              return uniqueProducts;
            });

            setHasMore(products.length + newProducts.length < total);
          }
        }
      } catch (error) {
        console.error("Erro ao buscar produtos:", error);
      } finally {
        if (!cancelRequest) setLoading(false);
      }
    };

    fetchProducts();

    return () => {
      cancelRequest = true;
    };
  }, [filters, page]);

  const lastProductRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          setPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  const resetProducts = () => {
    setProducts([]);
    setPage(1);
    setHasMore(true);
  };

  return {
    products,
    categories,
    filters,
    setFilters,
    loading,
    hasMore,
    lastProductRef,
    resetProducts,
  };
};
