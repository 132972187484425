import { styled } from "@mui/material/styles";
import { Box, Paper, Avatar, Divider, Button, Typography } from "@mui/material";

export const StyledContainer = styled(Box)(({ theme }) => ({
  maxWidth: 1100,
  margin: "0 auto",
  padding: theme.spacing(3),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(5),
  },
  borderRadius: theme.shape.borderRadius * 2,
}));

export const GradientTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: theme.spacing(3),
  background: "linear-gradient(to right, #2196F3, #42A5F5)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(5),
  },
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: "0 6px 15px rgba(0, 0, 0, 0.1)",
  backgroundColor: theme.palette.background.paper,
}));

export const AvatarContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(3),
  flexWrap: "wrap",
  gap: theme.spacing(2),
}));

export const AvatarWithRing = styled(Avatar)({
  width: 72,
  height: 72,
  fontSize: "2rem",
  position: "relative",
  boxShadow: "0 0 0 3px white, 0 0 0 6px #90caf9",
});

export const ColoredDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  borderColor: "#90caf9",
}));

export const AddressSectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
}));

export const ActionButtonsContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: "flex",
  justifyContent: "flex-end",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: theme.spacing(1),
  },
}));

export const OutlinedButton = styled(Button)({
  borderColor: "#2196F3",
  color: "#2196F3",
  "&:hover": {
    borderColor: "#1565c0",
    backgroundColor: "#E3F2FD",
  },
});

export const FilledButton = styled(Button)({
  background: "linear-gradient(90deg, #2196F3 0%, #42A5F5 100%)",
  color: "#fff",
  "&:hover": {
    background: "linear-gradient(90deg, #1565c0 0%, #1e88e5 100%)",
  },
});
