import { useState, useEffect } from "react";
import baseApi from "~/common/services/base-api";

interface InstallmentOption {
  quantity: number;
  amount: number;
  totalAmount: number;
  interestFree: boolean;
}

export const useInstallments = (totalAmount: number, cardBin: string) => {
  const [installments, setInstallments] = useState<InstallmentOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchInstallments = async () => {
      if (!cardBin || cardBin.length < 6 || !totalAmount) return;

      setLoading(true);
      try {
        const response = await baseApi.get<InstallmentOption[]>(
          "/orders/installments",
          {
            params: {
              amount: totalAmount,
              cardBin: cardBin,
            },
          },
        );
        setInstallments(response.data);
        setError(null);
      } catch (err) {
        setError("Erro ao carregar opções de parcelamento");
        setInstallments([]);
      } finally {
        setLoading(false);
      }
    };

    fetchInstallments();
  }, [totalAmount, cardBin]);

  return { installments, loading, error };
};
