import moment from "moment";

export function formatCPF(cpf: string): string {
  const onlyNumbers = cpf.replace(/\D/g, "");

  if (onlyNumbers.length === 11) {
    return onlyNumbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  return cpf;
}

export function formatPhone(phone: string): string {
  const onlyNumbers = phone.replace(/\D/g, "");

  if (onlyNumbers.length === 11) {
    return onlyNumbers.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
  } else if (onlyNumbers.length === 10) {
    return onlyNumbers.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  }

  return phone;
}

export function formatDate(dateString: string): string {
  return moment(dateString).format("DD/MM/YYYY");
}
