const AboutSection: React.FC = () => {
  const fundationYear = 1997;
  const currentYear = new Date().getFullYear();
  const yearsOfExperience = currentYear - fundationYear;

  return (
    <div className="about-section-inline" id="about-section-inline">
      <h2>Por que escolher o CR Cursos Preparatórios?</h2>
      <hr />
      <p>
        São mais de {yearsOfExperience} anos de Experiência Preparando Alunos
        nas Diversas Áreas em Concursos Públicos. Em todos esses Anos fomos
        Aperfeiçoando nossa prática de Ensino, o que nos levou a aprovar mais de
        1500 alunos até o momento. Temos hoje aulas Presenciais (Presidente
        Prudente), Online e Online ao Vivo, o que nos permite atender e
        concretizar os Sonhos de milhares de Alunos.
      </p>
    </div>
  );
};

export default AboutSection;
