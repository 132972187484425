import { Theme } from "@material-ui/core";
import { Lock, Eye, EyeOff } from "lucide-react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Stack, useMediaQuery } from "@mui/system";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo_azul from "~/common/assets/logo_azul.webp";
import Footer from "~/common/components/footer/footer";
import { FirstHeaderSession } from "~/common/components/header/first-header-session/first-header-session";
import baseApi from "~/common/services/base-api";
import { ArrowBack } from "@mui/icons-material";
import { CustomSnackBar } from "~/common/components/custom-snackbar";

const ResetPassword: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [showFeedback, setShowFeedback] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  const smallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      setFeedbackMessage("Link de redefinição de senha inválido ou expirado.");
      setIsSuccess(false);
      setShowFeedback(true);
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    }
  }, [token, navigate]);

  const validatePasswords = () => {
    const newErrors = {
      password: "",
      confirmPassword: "",
    };

    if (passwords.password.length < 8) {
      newErrors.password = "A senha deve ter no mínimo 8 caracteres";
    }

    if (!/[A-Z]/.test(passwords.password)) {
      newErrors.password = "A senha deve conter pelo menos uma letra maiúscula";
    }

    if (!/[0-9]/.test(passwords.password)) {
      newErrors.password = "A senha deve conter pelo menos um número";
    }

    if (passwords.password !== passwords.confirmPassword) {
      newErrors.confirmPassword = "As senhas não coincidem";
    }

    setErrors(newErrors);
    return !newErrors.password && !newErrors.confirmPassword;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validatePasswords()) {
      return;
    }

    setIsLoading(true);

    try {
      await baseApi.post("/user/password-reset/confirm", {
        token,
        newPassword: passwords.password,
        confirmPassword: passwords.confirmPassword,
      });
      setIsSuccess(true);
      setFeedbackMessage("Senha alterada com sucesso! Redirecionando...");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error: any) {
      setIsSuccess(false);
      setFeedbackMessage(
        error.response?.data?.message || "Erro ao redefinir senha.",
      );
    } finally {
      setIsLoading(false);
      setShowFeedback(true);
    }
  };

  const handlePasswordChange =
    (field: "password" | "confirmPassword") =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPasswords((prev) => ({
        ...prev,
        [field]: e.target.value,
      }));
      if (errors[field]) {
        setErrors((prev) => ({
          ...prev,
          [field]: "",
        }));
      }
    };

  const togglePasswordVisibility = (field: "password" | "confirm") => () => {
    setShowPassword((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  if (!token) {
    return null;
  }

  return (
    <>
      <FirstHeaderSession />
      <Container component="main" sx={{ py: 4 }}>
        <Box sx={{ maxWidth: 600, mx: "auto" }}>
          <Card elevation={3}>
            <IconButton
              onClick={() => navigate(-1)}
              sx={{
                bgcolor: "background.paper",
                boxShadow: 1,
                "&:hover": { bgcolor: "grey.100" },
                marginLeft: 2,
                marginTop: 2,
              }}
            >
              <ArrowBack />
            </IconButton>
            <CardContent sx={{ p: 4 }}>
              <Stack alignItems="center" mb={4}>
                <Box
                  component="img"
                  src={logo_azul}
                  alt="Logo"
                  sx={{
                    width: 96,
                    height: 96,
                    objectFit: "contain",
                  }}
                  loading="lazy"
                />
                <Typography
                  variant={smallScreen ? "h5" : "h4"}
                  fontWeight="bold"
                  textAlign="center"
                >
                  Redefinir Senha
                </Typography>
                <Typography color="text.secondary" textAlign="center">
                  Digite sua nova senha
                </Typography>
              </Stack>

              <Box component="form" onSubmit={handleSubmit} noValidate>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Nova senha"
                  type={showPassword.password ? "text" : "password"}
                  id="password"
                  value={passwords.password}
                  onChange={handlePasswordChange("password")}
                  error={!!errors.password}
                  helperText={errors.password}
                  sx={{ mb: 3 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock size={20} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={togglePasswordVisibility("password")}
                          edge="end"
                        >
                          {showPassword.password ? (
                            <EyeOff size={20} />
                          ) : (
                            <Eye size={20} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirme a nova senha"
                  type={showPassword.confirm ? "text" : "password"}
                  id="confirmPassword"
                  value={passwords.confirmPassword}
                  onChange={handlePasswordChange("confirmPassword")}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                  sx={{ mb: 4 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock size={20} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={togglePasswordVisibility("confirm")}
                          edge="end"
                        >
                          {showPassword.confirm ? (
                            <EyeOff size={20} />
                          ) : (
                            <Eye size={20} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  disabled={
                    isLoading ||
                    !passwords.password ||
                    !passwords.confirmPassword
                  }
                  sx={{
                    py: 1.5,
                    position: "relative",
                  }}
                >
                  {isLoading ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  ) : (
                    "Redefinir Senha"
                  )}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>

        <CustomSnackBar
          approvalSnackbarOpen={showFeedback}
          timeToClose={6000}
          handleCloseApprovalSnackbar={() => setShowFeedback(false)}
        >
          <Alert severity={isSuccess ? "success" : "error"} variant="filled">
            {feedbackMessage}
          </Alert>
        </CustomSnackBar>
      </Container>
      <Footer />
    </>
  );
};

export default ResetPassword;
