import { Box, Button, CardContent, CardMedia, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BaseScreen } from "~/common/components/base-screen/base-screen";
import GenericListPage from "~/common/components/generic-list-page/generic-list-page";
import { getCourses } from "~/features/courses-face-to-face/services/get-courses";
import { Courses } from "~/features/courses-face-to-face/types/courses-face-to-face";
import { cleanUpText } from "~/utils/clean-up-text";

const CoursesListPage = () => {
  const navigate = useNavigate();

  return (
    <BaseScreen>
      <GenericListPage<Courses>
        title="Cursos Presenciais"
        fetchItems={(page, pageSize) => getCourses(page, pageSize)}
        pageSize={9}
        getItemLink={(course) => `/cursos-presenciais/${course.id}`}
        renderItemContent={(course) => (
          <Box>
            <CardMedia
              component="img"
              height="180"
              image={course.imageUrl || "/placeholder-image.jpg"}
              alt={course.title}
            />
            <CardContent>
              <Typography variant="h6">{course.title}</Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {cleanUpText(course.description ?? "")}
              </Typography>
            </CardContent>
          </Box>
        )}
        renderItemActions={(course) => (
          <Button
            variant="contained"
            color="primary"
            sx={{ width: "40%", marginTop: "8px", marginBottom: "16px" }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/cursos-presenciais/${course.id}`);
            }}
          >
            Saiba Mais
          </Button>
        )}
      />
    </BaseScreen>
  );
};

export default CoursesListPage;
