import { ArrowBack } from "@mui/icons-material";
import {
  Alert,
  Box,
  Card,
  CardContent,
  Container,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logoAzul from "~/common/assets/logo_azul.webp";
import { Copyright } from "~/common/components/copy-right/copy-right";
import { FirstHeaderSession } from "~/common/components/header/first-header-session/first-header-session";
import { useIsMobile } from "~/common/hooks/use-is-mobile";
import { SignUpForm } from "./components/signup-form/signup-form";
import { CustomSnackBar } from "~/common/components/custom-snackbar";

function Onboarding() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const handleCloseSnackbar = () => {
    setIsError(false);
    setFeedbackMessage("");
  };

  return (
    <Box sx={{ minHeight: "100vh", bgcolor: "grey.50" }}>
      <Box sx={{ width: "100%", bgcolor: "background.paper", boxShadow: 1 }}>
        <FirstHeaderSession />
      </Box>

      <Container component="main" sx={{ py: { xs: 4, md: 8 } }}>
        <Card
          elevation={2}
          sx={{
            maxWidth: 960,
            mx: "auto",
            borderRadius: 2,
            position: "relative",
            overflow: "visible",
          }}
        >
          <IconButton
            onClick={() => navigate(-1)}
            sx={{
              position: "absolute",
              left: { xs: 16, md: 24 },
              top: { xs: 16, md: 24 },
              bgcolor: "background.paper",
              boxShadow: 1,
              "&:hover": { bgcolor: "grey.100" },
            }}
          >
            <ArrowBack />
          </IconButton>

          <CardContent sx={{ p: { xs: 3, md: 6 } }}>
            <Stack spacing={4} alignItems="center">
              <Box
                component="img"
                src={logoAzul}
                alt="Logo"
                sx={{
                  width: { xs: 80, md: 120 },
                  height: { xs: 80, md: 120 },
                  objectFit: "contain",
                }}
                loading="lazy"
              />

              <Stack spacing={1} alignItems="center" maxWidth="sm">
                <Typography
                  variant={isMobile ? "h5" : "h4"}
                  component="h1"
                  fontWeight="bold"
                  textAlign="center"
                >
                  Crie sua conta
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  textAlign="center"
                >
                  Preencha seus dados para começar
                </Typography>
              </Stack>

              <Box sx={{ width: "100%" }}>
                <SignUpForm
                  isLoading={isLoading}
                  isSuccess={isSuccess}
                  isError={isError}
                  setFeedbackMessage={setFeedbackMessage}
                  setIsError={setIsError}
                  setIsLoading={setIsLoading}
                  setIsSuccess={setIsSuccess}
                />
              </Box>
            </Stack>
          </CardContent>
        </Card>

        <Box sx={{ mt: 4, textAlign: "center" }}>
          <Copyright />
        </Box>
      </Container>

      <CustomSnackBar
        approvalSnackbarOpen={isError}
        timeToClose={6000}
        handleCloseApprovalSnackbar={handleCloseSnackbar}
      >
        <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
          {feedbackMessage}
        </Alert>
      </CustomSnackBar>
    </Box>
  );
}

export default Onboarding;
