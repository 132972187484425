export enum Role {
  ADMIN = "admin",
  VISITANTE = "visitante",
  CLIENTE = "cliente",
  ALUNO = "aluno",
}

export interface UserSession {
  isLogged: boolean;
  email: string;
  name: string;
  userId: number;
  role: Role;
}
