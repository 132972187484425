import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Role } from "~/common/types/user-session";

interface AuthState {
  isLogged: boolean;
  token: string | null;
  user: {
    email: string;
    name: string;
    userId: number;
    role: Role;
  } | null;
}

const initialState: AuthState = {
  isLogged: false,
  token: null,
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess(
      state,
      action: PayloadAction<{ token: string; user: AuthState["user"] }>,
    ) {
      state.isLogged = true;
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    logout(state) {
      state.isLogged = false;
      state.token = null;
      state.user = null;
    },
    updateUser(state, action: PayloadAction<AuthState["user"]>) {
      state.user = action.payload;
    },
    updateRole(state, action: PayloadAction<string>) {
      if (state.user) {
        state.user.role = action.payload as Role;
      }
    },
  },
});

export const { loginSuccess, logout, updateRole, updateUser } =
  authSlice.actions;
export default authSlice.reducer;
