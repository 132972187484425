import {
  Box,
  Button,
  Paper,
  styled,
  Typography,
  TextField,
} from "@mui/material";

export const CartContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  [theme.breakpoints.up("sm")]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  [theme.breakpoints.up("md")]: {
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

export const EmptyCartContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  color: theme.palette.text.secondary,
  padding: theme.spacing(2),
  minHeight: "50vh",
  [theme.breakpoints.up("sm")]: {
    minHeight: "60vh",
  },
}));

export const CartPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fafafa",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(3),
  },
}));

export const CartItemContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid #ddd",
  paddingBottom: theme.spacing(2),
  flexWrap: "wrap",
  rowGap: theme.spacing(2),
}));

export const ItemInfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  flexWrap: "wrap",
}));

export const ProductImage = styled("img")(({ theme }) => ({
  width: "70px",
  height: "70px",
  objectFit: "cover",
  borderRadius: 4,
  [theme.breakpoints.up("sm")]: {
    width: "80px",
    height: "80px",
  },
  loading: "lazy",
}));

export const ProductTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: theme.typography.body1.fontSize,
  [theme.breakpoints.up("sm")]: {
    fontSize: theme.typography.subtitle1.fontSize,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: theme.typography.h6.fontSize,
  },
}));

export const ProductDescription = styled(Typography)(({ theme }) => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  marginTop: "4px",
  marginBottom: "8px",
  color: theme.palette.text.secondary,
  maxWidth: "250px",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "350px",
  },
}));

export const CartActionsRow = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export const CartBottomActions = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
}));

export const QuantityTextField = styled(TextField)(({ theme }) => ({
  width: "60px",
  [theme.breakpoints.up("sm")]: {
    width: "80px",
  },
}));

export const ClearCartButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    marginBottom: 0,
  },
}));
