import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  IconButton,
  Typography,
  Alert,
  CircularProgress,
  Stack,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, ApplicationState } from "~/redux/store";
import { fetchStudentCourses } from "~/redux/slicers/studentCoursesSlicer";
import { StudentCourse } from "~/features/student-area/types/student-area";
import { BaseScreen } from "~/common/components/base-screen/base-screen";
import { CourseProgress } from "../components/course-progress/course-progress";
import { ModulesList } from "../components/modules-list/modules-list";
import { LessonView } from "../components/lesson-view/lesson-view";
import {
  useCourseProgress,
  useSelectedContent,
} from "../hooks/use-custom-hooks";

const CourseDetailsPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams<{ id: string }>();
  const lessonViewRef = useRef<HTMLDivElement>(null);

  const { courses, loading, error } = useSelector(
    (state: ApplicationState) => state.studentCourses,
  );

  const [localCourse, setLocalCourse] = useState<StudentCourse | undefined>(
    undefined,
  );

  const {
    selectedModuleId,
    selectedLessonId,
    openModules,
    handleModuleClick,
    handleLessonClick,
    getSelectedLesson,
  } = useSelectedContent(localCourse, lessonViewRef);

  const { handleCompleteLesson } = useCourseProgress(setLocalCourse);

  useEffect(() => {
    if (!courses.length) {
      dispatch(fetchStudentCourses());
    }
  }, [dispatch, courses.length]);

  useEffect(() => {
    const reduxCourse = courses.find((c) => Number(c.id) === Number(id));
    if (reduxCourse) {
      setLocalCourse({ ...reduxCourse });
    }
  }, [courses, id]);

  if (loading) {
    return (
      <BaseScreen>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 8 }}>
          <CircularProgress size={60} />
        </Box>
      </BaseScreen>
    );
  }

  if (error) {
    return (
      <BaseScreen>
        <Alert severity="error" sx={{ mt: 4 }}>
          {error}
        </Alert>
      </BaseScreen>
    );
  }

  if (!localCourse) {
    return (
      <BaseScreen>
        <Alert severity="info">Curso não encontrado.</Alert>
      </BaseScreen>
    );
  }

  return (
    <BaseScreen>
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Box sx={{ mb: 4, display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => navigate(-1)} color="primary">
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h4"
            sx={{ ml: 2, fontWeight: "bold", flexGrow: 1 }}
          >
            {localCourse.title}
          </Typography>
          {localCourse.endDate && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography variant="body2" color="text.secondary">
                Acesso até: {new Date(localCourse.endDate).toLocaleString()}
              </Typography>
            </Box>
          )}
        </Box>

        <Stack spacing={4}>
          <CourseProgress progress={localCourse.progress ?? 0} />

          <Stack direction={{ xs: "column", md: "row" }} spacing={4}>
            <Box sx={{ width: { xs: "100%", md: 300 } }}>
              <ModulesList
                modules={localCourse.modules}
                selectedModuleId={selectedModuleId}
                selectedLessonId={selectedLessonId}
                openModules={openModules}
                onModuleClick={handleModuleClick}
                onLessonSelect={handleLessonClick}
              />
            </Box>

            <Box sx={{ flex: 1 }} ref={lessonViewRef}>
              <LessonView
                lesson={getSelectedLesson()}
                onComplete={handleCompleteLesson}
                supportMaterials={localCourse.product.documents}
              />
            </Box>
          </Stack>
        </Stack>
      </Container>
    </BaseScreen>
  );
};

export default CourseDetailsPage;
