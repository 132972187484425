import styled from "@mui/system/styled";

export const PhotoGrid = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
  gap: "16px",
  marginTop: "16px",
});

export const PhotoCard = styled("div")({
  position: "relative",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
  height: "300px",
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
    loading: "lazy",
  },
});

export const PhotoOverlay = styled("div")({
  position: "absolute",
  bottom: "0",
  left: "0",
  right: "0",
  background: "rgba(0, 0, 0, 0.5)",
  color: "white",
  padding: "20px",
  textAlign: "center",
  opacity: "0",
  transition: "opacity 0.3s",
  "&:hover": {
    opacity: "1",
  },
});

export const PhotoTitle = styled("h3")({
  fontSize: "1.2rem",
  margin: "0",
});
