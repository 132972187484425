import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import theme from "./theme/theme";
import ScrollToTop from "./utils/scroll-top";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const removeInitialLoading = () => {
  const initialLoading = document.querySelector(".initial-loading");
  if (!initialLoading) return;

  (initialLoading as HTMLElement).offsetHeight;

  (initialLoading as HTMLElement).style.opacity = "0";
  (initialLoading as HTMLElement).style.transition = "opacity 0.3s ease";

  const removeElement = () => {
    initialLoading.remove();
    initialLoading.removeEventListener("transitionend", removeElement);
  };

  initialLoading.addEventListener("transitionend", removeElement);

  setTimeout(removeElement, 350);
};

root.render(
  <Router>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <ScrollToTop />
          <App onLoad={removeInitialLoading} />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </Router>,
);
