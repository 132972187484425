import { Category } from "~/common/entities/category";
import baseApi from "~/common/services/base-api";

interface GetCategoriesResponse {
  response: {
    data: Category[];
    page: number;
    pageSize: number;
    total: number;
  };
}

export const getCategories = async (
  pageNumber: number,
  pageSize: number,
  type: { isHandout: boolean; isRecordedCourse: boolean },
): Promise<GetCategoriesResponse | null> => {
  try {
    const response = await baseApi.get("/categories", {
      params: {
        page: pageNumber,
        pageSize: pageSize,
        isHandout: type.isHandout,
        isRecordedCourse: type.isRecordedCourse,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar categorias:", error);
    return null;
  }
};
