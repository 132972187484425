import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Pagination,
  Box,
  TableSortLabel,
} from "@mui/material";
import { getApproved } from "../services/get-approved";
import { BaseScreen } from "~/common/components/base-screen/base-screen";
import { useDebounce } from "~/common/hooks/use-debounce";
import { BaseLoader } from "~/common/components/base-loader/base-loader";

interface Approved {
  id: number;
  studentName: string;
  concourse: string;
  year: string;
}

type Order = "asc" | "desc";

const ApprovedList = () => {
  const [approved, setApproved] = useState<Approved[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<keyof Approved>("year");
  const [order, setOrder] = useState<Order>("asc");
  const pageSize = 50;

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const fetchApproved = useCallback(
    async (page: number, search = "") => {
      setLoading(true);
      const approvedData = await getApproved(
        page,
        pageSize,
        search,
        orderBy,
        order,
      );

      if (!approvedData) {
        setLoading(false);
        return;
      }

      setApproved(approvedData.response?.data);
      setTotalPages(Math.ceil(approvedData.response.total / pageSize));
      setLoading(false);
    },
    [orderBy, order, pageSize],
  );

  useEffect(() => {
    fetchApproved(currentPage, debouncedSearchTerm);
  }, [currentPage, debouncedSearchTerm, fetchApproved]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setCurrentPage(page);
  };

  const handleSort = (property: keyof Approved) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <BaseScreen>
      {!loading ? (
        <Container sx={{ mt: 5, mb: 10 }}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              mb: 3,
              color: "primary.main",
            }}
          >
            Lista de Aprovados
          </Typography>
          <TextField
            fullWidth
            placeholder="Busque por nome, concurso ou ano..."
            variant="outlined"
            sx={{ mb: 3 }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "studentName"}
                      direction={orderBy === "studentName" ? order : "asc"}
                      onClick={() => handleSort("studentName")}
                    >
                      Nome
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Concurso</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "year"}
                      direction={orderBy === "year" ? order : "asc"}
                      onClick={() => handleSort("year")}
                    >
                      Ano
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {approved?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.studentName}</TableCell>
                    <TableCell>{item.concourse}</TableCell>
                    <TableCell>{item.year}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {approved?.length === 0 && (
            <Typography variant="body1" sx={{ textAlign: "center", mt: 3 }}>
              Nenhum aprovado encontrado.
            </Typography>
          )}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              size="large"
            />
          </Box>
        </Container>
      ) : (
        <BaseLoader />
      )}
    </BaseScreen>
  );
};

export default ApprovedList;
