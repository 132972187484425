import { Divider, List, ListItemText, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "~/redux/store";
import {
  ActionButton,
  ListItemStyled,
  PaperStyled,
  Title,
  TotalTypography,
} from "./order-sumary.styles";
import { CartItem } from "~/redux/slicers/cartSlicer";
import { calculateTotal, calculateUnitPrice } from "~/utils/calculate-price";

interface OrderSummaryProps {
  onNext: () => void;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({ onNext }) => {
  const cartItems = useSelector((state: ApplicationState) => state.cart.items);

  const total = calculateTotal(cartItems);

  return (
    <PaperStyled elevation={3}>
      <Title variant="h6">Resumo do Pedido</Title>
      <List disablePadding>
        {cartItems.map((item: CartItem) => (
          <ListItemStyled key={item.product.id}>
            <ListItemText
              primary={item.product.title || "Produto Sem Título"}
              secondary={`${item.quantity} ${
                item.quantity > 1 ? "Apostilas" : "Apostila"
              }`}
            />
            <Typography variant="body2">
              R$ {calculateUnitPrice(item)}
            </Typography>
          </ListItemStyled>
        ))}
        <Divider />
        <ListItemStyled>
          <ListItemText primary="Total" />
          <TotalTypography variant="subtitle1">R$ {total}</TotalTypography>
        </ListItemStyled>
      </List>
      <ActionButton variant="contained" color="primary" onClick={onNext}>
        Continuar para Pagamento
      </ActionButton>
    </PaperStyled>
  );
};

export default OrderSummary;
