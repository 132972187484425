import baseApi from "~/common/services/base-api";

export const getCourses = async (page: number, pageSize: number) => {
  try {
    const response = await baseApi(
      `/face-to-face-courses?page=${page}&pageSize=${pageSize}`,
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar os cursos", error);
  }
};

export const getCourseById = async (id: number) => {
  try {
    const response = await baseApi(`/face-to-face-courses/${id}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar curso", error);
  }
};
