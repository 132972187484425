import ArticleIcon from "@mui/icons-material/Article";
import DescriptionIcon from "@mui/icons-material/Description";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  alpha,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Document } from "~/features/student-area/types/student-area";

export const DocumentMenu: React.FC<{ documents: Document[] }> = ({
  documents,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getFileIcon = (url: string) => {
    const extension = url.split(".").pop()?.toLowerCase();
    return extension === "pdf" ? <PictureAsPdfIcon /> : <DescriptionIcon />;
  };

  if (!documents || documents.length === 0) return null;

  if (documents.length === 1) {
    return (
      <IconButton
        color="primary"
        component="a"
        href={documents[0].url}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          bgcolor: alpha(theme.palette.primary.main, 0.1),
          "&:hover": {
            bgcolor: alpha(theme.palette.primary.main, 0.2),
          },
        }}
      >
        <ArticleIcon />
      </IconButton>
    );
  }

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        startIcon={<ArticleIcon />}
        sx={{ minWidth: "130px" }}
      >
        Materiais
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 3,
          sx: {
            minWidth: 200,
            maxHeight: "300px",
          },
        }}
      >
        {documents.map((doc, index) => (
          <MenuItem
            key={index}
            component="a"
            href={doc.url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClose}
          >
            <ListItemIcon>{getFileIcon(doc.url)}</ListItemIcon>
            <ListItemText
              primary={doc.title || `Material ${index + 1}`}
              primaryTypographyProps={{
                sx: {
                  fontSize: "0.875rem",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
