import DeleteIcon from "@mui/icons-material/Delete";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthModal from "~/common/components/auth-modal/auth-modal";
import { BaseScreen } from "~/common/components/base-screen/base-screen";
import {
  clearCart,
  removeFromCart,
  updateQuantity,
} from "~/redux/slicers/cartSlicer";
import { ApplicationState } from "~/redux/store";

import {
  CartContainer,
  EmptyCartContainer,
  CartPaper,
  CartItemContainer,
  ItemInfoBox,
  ProductImage,
  ProductTitle,
  ProductDescription,
  CartActionsRow,
  CartBottomActions,
  QuantityTextField,
  ClearCartButton,
} from "./cart.styles";

import { Box, Button } from "@mui/material";
import { cleanUpText } from "~/utils/clean-up-text";
import { calculateTotal, calculateUnitPrice } from "~/utils/calculate-price";

const CartPage: React.FC = () => {
  const isLogged = useSelector(
    (state: ApplicationState) => state.auth.isLogged,
  );
  const navigate = useNavigate();

  const cartItems = useSelector((state: ApplicationState) => state.cart.items);
  const dispatch = useDispatch();

  const [authModalOpen, setAuthModalOpen] = useState(false);

  const handleRemoveItem = (productId: number) => {
    dispatch(removeFromCart(productId));
  };

  const handleQuantityChange = (productId: number, quantity: number) => {
    dispatch(updateQuantity({ productId, quantity }));
  };

  const handleClearCart = () => {
    dispatch(clearCart());
  };

  const handleContinueShopping = () => {
    navigate("/");
  };

  const handleFinalizePurchase = () => {
    if (isLogged) {
      navigate("/finalizar-compra");
    } else {
      setAuthModalOpen(true);
    }
  };

  const handleOpenLogin = () => {
    setAuthModalOpen(true);
  };

  const handleCloseAuthModal = () => {
    setAuthModalOpen(false);
  };

  const total = calculateTotal(cartItems);

  return (
    <BaseScreen>
      <CartContainer>
        {cartItems.length > 0 && (
          <Typography variant="h4" mb={3} fontWeight="bold">
            Meu Carrinho
          </Typography>
        )}

        {cartItems.length === 0 ? (
          <EmptyCartContainer>
            <ShoppingCartOutlinedIcon
              sx={{ fontSize: 80, mb: 2, color: "primary.main" }}
            />
            <Typography variant="h5" mb={1}>
              Seu carrinho está vazio!
            </Typography>
            <Typography variant="body1" mb={3}>
              Parece que você ainda não adicionou nenhum produto ao carrinho.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleContinueShopping}
              size="large"
            >
              Continuar Comprando
            </Button>
          </EmptyCartContainer>
        ) : (
          <>
            <CartPaper elevation={1}>
              <Grid container spacing={2}>
                {cartItems.map((item) => {
                  return (
                    <Grid item xs={12} key={item.product.id}>
                      <CartItemContainer>
                        <ItemInfoBox>
                          <ProductImage
                            src={item.product.imageUrl || "/placeholder.jpg"}
                            alt={item.product.title}
                          />
                          <Box>
                            <ProductTitle variant="h6">
                              {item.product.title}
                            </ProductTitle>
                            <ProductDescription variant="body2">
                              {cleanUpText(
                                item.product.resumedDescription ?? "",
                              )}
                            </ProductDescription>
                            {item.product.totalDocumentsCount !== undefined && (
                              <Typography
                                variant="body2"
                                color="text.primary"
                                fontWeight="500"
                              >
                                {item.product.totalDocumentsCount} Apostila
                                {item.product.totalDocumentsCount > 1
                                  ? "s"
                                  : ""}
                              </Typography>
                            )}
                          </Box>
                        </ItemInfoBox>

                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        >
                          <QuantityTextField
                            type="number"
                            label="Qtd"
                            variant="outlined"
                            size="small"
                            value={item.quantity}
                            onChange={(e) =>
                              handleQuantityChange(
                                item.product.id,
                                parseInt(e.target.value, 10),
                              )
                            }
                            InputProps={{ inputProps: { min: 1 } }}
                          />

                          <Typography
                            variant="subtitle1"
                            color="text.primary"
                            fontWeight="bold"
                          >
                            R$ {calculateUnitPrice(item)}
                          </Typography>

                          <IconButton
                            onClick={() => handleRemoveItem(item.product.id)}
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </CartItemContainer>
                    </Grid>
                  );
                })}
              </Grid>

              <Divider sx={{ my: 3 }} />

              <CartActionsRow>
                <ClearCartButton
                  variant="outlined"
                  color="error"
                  onClick={handleClearCart}
                >
                  Limpar Carrinho
                </ClearCartButton>
                <Typography variant="h6" fontWeight="500">
                  Total:{" "}
                  <Typography
                    component="span"
                    variant="h6"
                    fontWeight="bold"
                    color="primary"
                  >
                    R$ {total}
                  </Typography>
                </Typography>
              </CartActionsRow>

              <CartBottomActions>
                {isLogged ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{ minWidth: "200px" }}
                    onClick={handleFinalizePurchase}
                  >
                    Finalizar Compra
                  </Button>
                ) : (
                  <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      sx={{ minWidth: "150px" }}
                      onClick={handleOpenLogin}
                    >
                      Fazer Login
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      sx={{ minWidth: "150px" }}
                      onClick={() => navigate("/cadastro")}
                    >
                      Cadastrar-se
                    </Button>
                  </Box>
                )}
              </CartBottomActions>
            </CartPaper>

            <AuthModal
              open={authModalOpen}
              handleClose={handleCloseAuthModal}
              redirectPath="/finalizar-compra"
            />
          </>
        )}
      </CartContainer>
    </BaseScreen>
  );
};

export default CartPage;
