import { Avatar, Box, Button, Card, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";

export const Container = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(2, 4),
  pb: theme.spacing(4),
}));

export const Title = styled(Typography)({
  color: "#1976d2",
  fontWeight: "bold",
  textAlign: "center",
});

export const DownloadsGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const DownloadCard = styled(Card)(({ theme }) => ({
  borderRadius: 16,
  boxShadow: theme.shadows[3],
  transition: "transform 0.3s",
  backgroundColor: "#ffffff",
  "&:hover": {
    transform: "translateY(-10px)",
  },
}));

export const CardHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

export const HeaderAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: "#1976d2",
  width: 56,
  height: 56,
  marginRight: theme.spacing(2),
}));

export const DownloadButtons = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

export const EmptyStateBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
}));

export const EmptyIcon = styled(SentimentDissatisfiedIcon)(({ theme }) => ({
  fontSize: 60,
  color: "#484848",
  marginBottom: theme.spacing(2),
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  padding: theme.spacing(1, 4),
  boxShadow: theme.shadows[2],
  "&:hover": {
    boxShadow: theme.shadows[4],
  },
}));
