import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: ["Roboto", "Arial", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#1074e2",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#1B2F4C",
      contrastText: "#ffffff",
    },
  },
  spacing: 8,
});

export default theme;
