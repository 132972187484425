import React from "react";
import { useParams } from "react-router-dom";
import { getNewById } from "~/features/news/services/get-news";
import GenericDetailPage from "~/common/components/generic-detail-page/generic-detail-page";
import {
  ImageContainer,
  StyledImage,
  ContentBox,
  ContentText,
} from "./news-details.styles";
import { News } from "~/features/news/types/news";
import { BaseScreen } from "~/common/components/base-screen/base-screen";
import { formatAndSanitizeHTML } from "~/utils/format-and-sanitize-html";

const NewsDetailPage = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <BaseScreen>
      <GenericDetailPage<News>
        id={Number(id)}
        fetchItem={getNewById}
        renderContent={(news) => (
          <>
            <ImageContainer>
              <StyledImage
                src={news.imageUrl}
                alt={news.title}
                loading="lazy"
              />
            </ImageContainer>
            <ContentBox>
              <ContentText
                sx={{ whiteSpace: "pre-wrap" }}
                dangerouslySetInnerHTML={formatAndSanitizeHTML(news.content)}
              />
            </ContentBox>
          </>
        )}
        enableSharing={true}
        getTitle={(news) => news.title}
        getSubtitle={(news) =>
          `Publicado em: ${new Date(news.createdAt).toLocaleDateString("pt-BR")}`
        }
      />
    </BaseScreen>
  );
};

export default NewsDetailPage;
