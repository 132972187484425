import { styled } from "@mui/material/styles";
import { Paper, Typography, Box, Slider, Button, Divider } from "@mui/material";

export const FiltersContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  backgroundColor: "#f9f9fc",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

export const SectionTitle = styled(Typography)({
  fontWeight: "bold",
  color: "#333",
});

export const PriceRangeBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: theme.spacing(1),
  },
}));

export const StyledSlider = styled(Slider)({
  color: "#007FFF",
});

export const ApplyButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  textTransform: "none",
  fontWeight: "bold",
  width: "100%",
  backgroundColor: "#007FFF",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#005BBB",
  },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: `${theme.spacing(2)} 0`,
}));
