import { CartItem } from "~/redux/slicers/cartSlicer";

export const calculatePriceWithDiscount = (
  price: number,
  discount: number,
): number => {
  return price - (price * discount) / 100;
};

export const calculateTotal = (cartItems: CartItem[]): string => {
  const total = cartItems.reduce((sum: number, item: CartItem) => {
    const { product, quantity } = item;

    const hasValidDiscount =
      product.discount !== undefined && product.discount > 0;

    const price = hasValidDiscount
      ? calculatePriceWithDiscount(product.price ?? 0, product.discount ?? 0)
      : (product.price ?? 0);

    return sum + price * quantity;
  }, 0);

  return total.toFixed(2);
};

export const calculateUnitPrice = (item: CartItem): string => {
  const { product, quantity } = item;
  const hasValidDiscount =
    product.discount !== undefined && product.discount > 0;

  const pricePerUnit = hasValidDiscount
    ? calculatePriceWithDiscount(product.price ?? 0, product.discount ?? 0)
    : (product.price ?? 0);

  return (pricePerUnit * quantity).toFixed(2);
};
