import { UserProps, UserType } from "~/common/entities/user";
import baseApi from "~/common/services/base-api";

export const peopleOnboarding = async (data: UserProps) => {
  const response = await baseApi.post("/user/onboarding", {
    ...data,
    cpf: data.cpf.replace(/\D/g, ""),
    cep: data.cep.replace(/\D/g, ""),
    numTelefone: data.numTelefone.replace(/\D/g, ""),
    tipoUsuario: UserType.VISITANTE,
    numResidencial: parseInt(data.numResidencial.replace(/\D/g, "")),
  });

  return response.data;
};
