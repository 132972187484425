import baseApi from "~/common/services/base-api";

export const getApproved = async (
  page: number,
  pageSize: number,
  searchTerm?: string,
  orderBy?: string,
  order?: "asc" | "desc",
) => {
  try {
    const queryParameters = new URLSearchParams();
    queryParameters.append("page", page.toString());
    queryParameters.append("pageSize", pageSize.toString());
    if (searchTerm) {
      queryParameters.append("searchTerm", searchTerm);
    }
    if (orderBy) {
      queryParameters.append("orderBy", orderBy);
    }
    if (order) {
      queryParameters.append("order", order);
    }

    const response = await baseApi(`/approved?page=${page}&${queryParameters}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar o aprovados", error);
  }
};
