import React, { Suspense, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { BaseScreen } from "~/common/components/base-screen/base-screen";
import { Product } from "~/common/entities/product";
import { useIsMobile } from "~/common/hooks/use-is-mobile";
import baseApi from "~/common/services/base-api";
import { getHandouts } from "~/common/services/get-handouts";
import { getRecordedCourses } from "~/common/services/get-recorded-courses";
import AboutSection from "~/features/home/components/about-section/about-section";
import { getApproved } from "../approved/services/get-approved";
import { getCourses } from "../courses-face-to-face/services/get-courses";
import { Courses } from "../courses-face-to-face/types/courses-face-to-face";
import { getNews } from "../news/services/get-news";
import { News } from "../news/types/news";
import { BannerResponse } from "./components/banner-section/banner-section.types";
import ErrorBoundary from "./components/error-boundary/error-boundary";
import { HomeLoadingSkeleton } from "./components/home-loading-skeleton/home-loading-skeleton";
import IconsSection from "./components/icons-section/icons-section";

const BannerSection = React.lazy(
  () => import("./components/banner-section/banner-section"),
);
const ApprovedCounterSection = React.lazy(
  () =>
    import("./components/approved-counter-section/approved-counter-section"),
);
const BooksSection = React.lazy(
  () => import("./components/book-section/book-section"),
);
const FaceToFaceCursesSection = React.lazy(
  () =>
    import(
      "./components/face-to-face-courses-section/face-to-face-courses-section"
    ),
);
const RecordedCoursesSection = React.lazy(
  () =>
    import("./components/recorded-courses-section/recorded-courses-section"),
);
const YoutubeVideoSection = React.lazy(
  () => import("./components/youtube-section/youtube-section"),
);
const NewsSection = React.lazy(
  () => import("./components/news-section/news-section"),
);

const LoadingFallback = styled.div`
  padding: 20px;
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin: 10px 0;
`;

const Home = () => {
  const [banners, setBanners] = useState<BannerResponse[]>([]);
  const [news, setNews] = useState<News[]>([]);
  const [handouts, setHandouts] = useState<Product[]>([]);
  const [courses, setCourses] = useState<Courses[]>([]);
  const [approvedCounter, setApprovedCounter] = useState(0);
  const [recordedCourses, setRecordedCourses] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSecondaryLoading, setIsSecondaryLoading] = useState(false);
  const isMobile = useIsMobile();

  const defaultPageSizes = isMobile ? 3 : 6;
  const [videoUrl, setVideoUrl] = useState("");
  const [videoTitle, setVideoTitle] = useState("");

  const fetchPrimaryData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [bannersResponse, recordedCourses] = await Promise.all([
        baseApi.get("/banners"),
        getRecordedCourses(1, defaultPageSizes),
      ]);

      setBanners(bannersResponse?.data.banners || []);
      setRecordedCourses(recordedCourses?.response.data || []);
    } catch (error) {
      console.error("Erro ao carregar dados principais:", error);
    } finally {
      setIsLoading(false);
    }
  }, [defaultPageSizes]);

  const fetchSecondaryData = useCallback(async () => {
    setIsSecondaryLoading(true);
    try {
      const [handoutsData, newsData, videoResponse, coursesData, approvedData] =
        await Promise.all([
          getHandouts(1, defaultPageSizes),
          getNews(1, 10),
          baseApi("/ytvideos"),
          getCourses(1, 10),
          getApproved(1, 10),
        ]);

      setHandouts(handoutsData?.response.data || []);
      setNews(newsData?.response.data || []);

      if (videoResponse?.data.response.data?.length) {
        setVideoTitle(videoResponse.data.response.data[0].title);
        setVideoUrl(videoResponse.data.response.data[0].url);
      }

      setCourses(coursesData?.response.data || []);
      setApprovedCounter(approvedData?.response.total || 0);
    } catch (error) {
      console.error("Erro ao carregar dados secundários:", error);
    } finally {
      setIsSecondaryLoading(false);
    }
  }, [defaultPageSizes]);

  useEffect(() => {
    fetchPrimaryData();
    fetchSecondaryData();
  }, [fetchPrimaryData, fetchSecondaryData]);

  if (isLoading) {
    return <HomeLoadingSkeleton />;
  }

  const renderBanners = () => {
    if (isMobile) {
      return null;
    }

    return (
      <ErrorBoundary>
        <Suspense
          fallback={<LoadingFallback>Carregando Banner...</LoadingFallback>}
        >
          <BannerSection banners={banners} />
        </Suspense>
      </ErrorBoundary>
    );
  };

  return (
    <BaseScreen>
      {renderBanners()}

      <AboutSection />

      <IconsSection />

      {isSecondaryLoading ? (
        <LoadingFallback>Carregando mais conteúdo...</LoadingFallback>
      ) : (
        <>
          <ErrorBoundary>
            <Suspense
              fallback={
                <LoadingFallback>Carregando Cursos Gravados...</LoadingFallback>
              }
            >
              <RecordedCoursesSection recordedCourses={recordedCourses} />
            </Suspense>
          </ErrorBoundary>

          <ErrorBoundary>
            <Suspense
              fallback={
                <LoadingFallback>Carregando Apostilas...</LoadingFallback>
              }
            >
              <BooksSection apostilas={handouts} />
            </Suspense>
          </ErrorBoundary>

          {videoTitle && (
            <ErrorBoundary>
              <Suspense
                fallback={
                  <LoadingFallback>Carregando Vídeo...</LoadingFallback>
                }
              >
                <YoutubeVideoSection
                  videoTitle={videoTitle}
                  videoUrl={videoUrl}
                />
              </Suspense>
            </ErrorBoundary>
          )}

          {news?.length > 0 && (
            <ErrorBoundary>
              <Suspense
                fallback={
                  <LoadingFallback>Carregando Notícias...</LoadingFallback>
                }
              >
                <NewsSection newsData={news} />
              </Suspense>
            </ErrorBoundary>
          )}

          {courses?.length > 0 && (
            <ErrorBoundary>
              <Suspense
                fallback={
                  <LoadingFallback>
                    Carregando Cursos Presenciais...
                  </LoadingFallback>
                }
              >
                <FaceToFaceCursesSection coursesData={courses} />
              </Suspense>
            </ErrorBoundary>
          )}

          <ErrorBoundary>
            <Suspense
              fallback={
                <LoadingFallback>Carregando Contador...</LoadingFallback>
              }
            >
              <ApprovedCounterSection approvedCounter={approvedCounter} />
            </Suspense>
          </ErrorBoundary>
        </>
      )}
    </BaseScreen>
  );
};

export default Home;
