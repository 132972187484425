import { styled } from "@mui/material/styles";
import { Box, Button, Chip, Grid, Typography } from "@mui/material";

export const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(2),
  margin: "0 auto",
  maxWidth: "1440px",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(4),
  },
}));

export const ProductDetailsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
}));

export const ProductImageWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("md")]: {
    flex: "0 0 50%",
    position: "sticky",
    top: theme.spacing(2),
  },
}));

export const ProductImage = styled("img")(({ theme }) => ({
  width: "100%",
  height: "auto",
  maxHeight: "450px",
  borderRadius: theme.shape.borderRadius,
  objectFit: "cover",
  [theme.breakpoints.up("md")]: {
    maxHeight: "550px",
  },
  loading: "lazy",
}));

export const ProductInfoContainer = styled(Box)(({ theme }) => ({
  flex: "1 1 50%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
}));

export const ChipsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(1),
}));

export const ProductFeatures = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1.5),
}));

export const ActionsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

export const PrimaryActionButton = styled(Button)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(1.5),
}));

export const IconButtonsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.dark,
  },
}));

export const DescriptionContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

export const RelatedProductsContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

export const SectionTitle = styled(Typography)({
  fontWeight: "bold",
});
