import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserProps } from "~/common/entities/user";
import { fetchAddress } from "~/common/services/get-address";
import { peopleOnboarding } from "~/features/onboarding/services/people-onboarding";
import { estadosBrasileiros } from "~/utils/estados-brasileiros";
import { niveisEscolares } from "~/utils/niveis-escolares";
import { maskCEP, maskCPF, maskPhone } from "~/utils/text-mask";

interface SignUpProps {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  setIsSuccess: (value: boolean) => void;
  setIsError: (value: boolean) => void;
  setFeedbackMessage: (value: string) => void;
  isSuccess: boolean;
  isError: boolean;
}

export const SignUpForm = ({
  isLoading,
  setIsLoading,
  setIsSuccess,
  setIsError,
  setFeedbackMessage,
  isSuccess,
  isError,
}: SignUpProps) => {
  const [user, setUser] = useState<UserProps>({
    cpf: "",
    nome: "",
    dataNascimento: "",
    bairro: "",
    endereco: "",
    cep: "",
    cidade: "",
    uf: "",
    numResidencial: "",
    numTelefone: "",
    email: "",
    nivelEscolar: "",
    senha: "",
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleCepChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const cep = maskCEP(e.currentTarget.value);
    setUser({ ...user, cep });
    const address = await fetchAddress(cep);
    if (address) {
      setUser({
        ...user,
        cep,
        endereco: address.endereco,
        bairro: address.bairro,
        cidade: address.cidade,
        uf: address.uf,
      });
    }
  };

  const isFormComplete = () => {
    return (
      user.cpf &&
      user.nome &&
      user.dataNascimento &&
      user.endereco &&
      user.cep &&
      user.cidade &&
      user.uf &&
      user.numResidencial &&
      user.numTelefone &&
      user.email &&
      user.nivelEscolar &&
      user.bairro &&
      user.senha &&
      confirmPassword
    );
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      await peopleOnboarding(user);
      setIsSuccess(true);
      navigate("/login");
    } catch (error: unknown) {
      setIsError(true);
      const err = error as unknown as any;
      setFeedbackMessage(
        err.response?.data?.message || "Erro ao cadastrar usuário",
      );
    }

    setIsLoading(false);
  };

  function renderSubmitButtonContent() {
    if (isLoading) {
      return <CircularProgress size={24} />;
    }

    if (isSuccess) return <CheckCircleIcon />;

    if (isError) return <ErrorIcon />;

    return "Cadastrar";
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordVisibility = () => {
    setConfirmShowPassword(!confirmShowPassword);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="nome"
            label="Nome Completo"
            name="nome"
            onChange={handleChange}
            value={user.nome}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="cpf"
            label="CPF"
            name="cpf"
            onChange={handleChange}
            value={maskCPF(user.cpf)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            type="email"
            onChange={handleChange}
            value={user.email}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="dataNascimento"
            label="Data de Nascimento"
            name="dataNascimento"
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
            value={user.dataNascimento}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="nivel-escolar-label">Nível Escolar</InputLabel>
            <Select
              labelId="nivel-escolar-label"
              id="nivelEscolar"
              name="nivelEscolar"
              value={user.nivelEscolar}
              label="Nível Escolar"
              onChange={handleSelectChange}
            >
              {niveisEscolares.map((nivel) => (
                <MenuItem key={nivel} value={nivel}>
                  {nivel}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="cep"
            label="CEP"
            name="cep"
            onChange={handleCepChange}
            value={user.cep}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="endereco"
            label="Endereço"
            name="endereco"
            onChange={handleChange}
            value={user.endereco}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="bairro"
            label="Bairro"
            name="bairro"
            onChange={handleChange}
            value={user.bairro}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="numTelefone"
            label="Número de Telefone"
            name="numTelefone"
            onChange={handleChange}
            value={maskPhone(user.numTelefone)}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="cidade"
            label="Cidade"
            name="cidade"
            onChange={handleChange}
            value={user.cidade}
          />
        </Grid>

        <Grid item xs={6} sm={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="numResidencial"
            label="N° Residência"
            name="numResidencial"
            onChange={handleChange}
            value={user.numResidencial}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="uf-label">UF</InputLabel>
            <Select
              labelId="uf-label"
              id="uf"
              value={user.uf}
              label="UF"
              name="uf"
              onChange={handleSelectChange}
            >
              {estadosBrasileiros.map((estado) => (
                <MenuItem key={estado} value={estado}>
                  {estado}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="senha"
            label="Senha"
            name="senha"
            type={showPassword ? "text" : "password"}
            onChange={handleChange}
            value={user.senha}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handlePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="confirmPassword"
            label="Confirme sua senha"
            name="confirmPassword"
            type={confirmShowPassword ? "text" : "password"}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setConfirmPassword(event.currentTarget.value)
            }
            value={confirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleConfirmPasswordVisibility}
                    edge="end"
                  >
                    {confirmShowPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={!isFormComplete() || isLoading}
      >
        {renderSubmitButtonContent()}
      </Button>
    </Box>
  );
};
