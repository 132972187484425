import React from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
  Box,
  Paper,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Book, CheckCircle, PlayCircle } from "lucide-react";

import { Module as ModuleType } from "~/features/student-area/types/student-area";

interface ModulesListProps {
  modules: ModuleType[];
  selectedModuleId: number | null;
  selectedLessonId: number | null;
  openModules: number[];
  onModuleClick: (moduleId: number) => void;
  onLessonSelect: (moduleId: number, lessonId: number) => void;
}

export const ModulesList: React.FC<ModulesListProps> = ({
  modules,
  selectedModuleId,
  selectedLessonId,
  openModules,
  onModuleClick,
  onLessonSelect,
}) => {
  const sortByOrder = <T extends { order: number }>(items: T[]): T[] =>
    [...items].sort((a, b) => a.order - b.order);

  return (
    <Paper
      sx={{
        p: 2,
        maxHeight: "80vh",
        overflowY: "auto",
        boxShadow: 3,
        borderRadius: 2,
      }}
      variant="outlined"
    >
      <List component="nav" aria-labelledby="modules-list" disablePadding>
        {sortByOrder(modules).map((module) => (
          <React.Fragment key={module.id}>
            <ListItemButton
              onClick={() => onModuleClick(module.id)}
              sx={{
                backgroundColor:
                  selectedModuleId === module.id
                    ? "rgba(25, 118, 210, 0.1)"
                    : "inherit",
                borderRadius: 1,
                mb: 1,
              }}
            >
              <ListItemIcon>
                <Box sx={{ color: "primary.main" }}>
                  <Book size={20} />
                </Box>
              </ListItemIcon>
              <ListItemText
                primary={`Módulo ${module.order}`}
                secondary={module.title}
                primaryTypographyProps={{ fontWeight: "medium" }}
              />
              {openModules.includes(module.id) ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItemButton>
            <Collapse
              in={openModules.includes(module.id)}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {sortByOrder(module.lessons).map((lesson) => (
                  <ListItemButton
                    key={lesson.id}
                    sx={{
                      pl: 4,
                      backgroundColor:
                        selectedLessonId === lesson.id
                          ? "rgba(25, 118, 210, 0.1)"
                          : "inherit",
                      borderRadius: 1,
                      mb: 0.5,
                    }}
                    onClick={() => onLessonSelect(module.id, lesson.id)}
                  >
                    <ListItemIcon>
                      {lesson.completed ? (
                        <CheckCircle color="green" size={20} />
                      ) : (
                        <PlayCircle color="grey" size={20} />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={`Aula ${lesson.order}`}
                      secondary={lesson.title}
                    />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};
