import React, { useState } from "react";
import {
  StyledCard,
  DiscountChip,
  StyledCardMedia,
  StyledCardContent,
  ProductTitle,
  PriceText,
  ButtonGroup,
  PrimaryButton,
  SecondaryButton,
} from "./product-card.styles";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Product } from "~/common/entities/product";
import { Typography, Chip, Box, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "~/redux/slicers/cartSlicer";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { cleanUpText } from "~/utils/clean-up-text";
import InstallmentsCalculator from "~/common/components/installments-calculator/installments-calculator";
import { CustomSnackBar } from "~/common/components/custom-snackbar";

interface ProductCardProps {
  product: Product;
}

export const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [snackOpen, setSnackOpen] = useState(false);

  const hasDocuments =
    product.totalDocumentsCount !== undefined &&
    product.totalDocumentsCount > 0;
  const hasLessons = product.totalLessonsCount && product.totalLessonsCount > 0;

  const handleNavigate = () => {
    navigate(`/produto/${product.id}`);
  };

  const handleAddToCart = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    dispatch(addToCart(product));
    setSnackOpen(true);
  };

  const handleBuyNow = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(addToCart(product));
    navigate("/carrinho", { state: { directBuy: true } });
  };

  return (
    <>
      <StyledCard onClick={handleNavigate}>
        {product.discount !== undefined && product.discount !== 0 && (
          <DiscountChip label={`${product.discount}% OFF`} />
        )}
        <StyledCardMedia
          component="img"
          image={product.imageUrl || "/placeholder.jpg"}
        />
        <StyledCardContent>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <ProductTitle variant="h6">{product.title}</ProductTitle>
            </Box>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                marginTop: "8px",
                marginBottom: "12px",
              }}
            >
              {cleanUpText(product.resumedDescription ?? "")}
            </Typography>

            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
              {product.category?.name && (
                <Chip
                  label={product.category.name}
                  variant="outlined"
                  size="small"
                  sx={{
                    fontWeight: 500,
                    borderColor: "primary.main",
                    color: "primary.main",
                  }}
                />
              )}
              {hasDocuments && (
                <Chip
                  label={`${product.totalDocumentsCount} PDF${
                    (product.totalDocumentsCount ?? 0) > 1 ? "s" : ""
                  }`}
                  variant="outlined"
                  size="small"
                  sx={{
                    fontWeight: 500,
                    borderColor: "secondary.main",
                    color: "secondary.main",
                  }}
                />
              )}
              {hasLessons && (
                <Chip
                  label={`${product.totalLessonsCount} Aula${
                    (product.totalLessonsCount ?? 0) > 1 ? "s" : ""
                  }`}
                  variant="outlined"
                  size="small"
                  sx={{
                    fontWeight: 500,
                    borderColor: "primary.main",
                    color: "primary.main",
                  }}
                />
              )}
            </Box>

            <Box sx={{ mb: 2 }}>
              <InstallmentsCalculator
                price={product.price ?? 0}
                discountedPrice={product.discountedPrice}
                minInstallmentValue={5}
                maxInstallments={12}
              />
            </Box>

            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 2 }}
            >
              {hasDocuments && (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <PictureAsPdfIcon color="secondary" fontSize="small" />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    fontWeight="500"
                  >
                    Apostilas em PDF
                  </Typography>
                </Box>
              )}
              {hasLessons && (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <OndemandVideoIcon color="primary" fontSize="small" />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    fontWeight="500"
                  >
                    Curso em vídeo
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>

          <ButtonGroup>
            <PrimaryButton onClick={handleBuyNow}>Comprar</PrimaryButton>
            <SecondaryButton
              startIcon={<ShoppingCartIcon />}
              onClick={handleAddToCart}
            >
              Adicionar ao Carrinho
            </SecondaryButton>
          </ButtonGroup>
        </StyledCardContent>
      </StyledCard>

      <CustomSnackBar
        approvalSnackbarOpen={snackOpen}
        timeToClose={3000}
        handleCloseApprovalSnackbar={() => setSnackOpen(false)}
      >
        <Alert severity="success" variant="filled">
          Produto adicionado ao carrinho!
        </Alert>
      </CustomSnackBar>
    </>
  );
};

export default ProductCard;
