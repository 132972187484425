import React, { useEffect, useState } from "react";
import { Typography, Grid, Avatar, Button, Divider, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getOrders } from "../services/get-orders";
import { BaseScreen } from "~/common/components/base-screen/base-screen";
import {
  Container,
  Title,
  OrdersGrid,
  OrderCard,
  StatusBox,
  StatusAvatar,
  CardContentStyled,
  ItemBox,
  EmptyStateBox,
  EmptyIcon,
  ActionButton,
} from "./orders.styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import ErrorIcon from "@mui/icons-material/Error";
import DownloadIcon from "@mui/icons-material/Download";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { BaseLoader } from "~/common/components/base-loader/base-loader";

interface OrderItem {
  id: string;
  status: string;
  createdAt: string;
  total: number;
  items: Array<{
    id: string;
    product: {
      id: string;
      titulo: string;
      imagem: string;
    };
    quantity: number;
    price: number;
  }>;
}

type StatusColor = "success" | "warning" | "error";

const translateStatus = (status: string) => {
  switch (status) {
    case "COMPLETED":
      return "Concluído";
    case "PENDING":
      return "Pendente";
    case "CANCELED":
      return "Falhou";
    default:
      return "Desconhecido";
  }
};

const getStatusDetails = (status: string) => {
  switch (status) {
    case "COMPLETED":
      return {
        label: "Concluído",
        color: "success",
        icon: <CheckCircleIcon />,
      };
    case "PENDING":
      return {
        label: "Pendente",
        color: "warning",
        icon: <HourglassEmptyIcon />,
      };
    case "CANCELED":
      return { label: "Falhou", color: "error", icon: <ErrorIcon /> };
    default:
      return { label: "Desconhecido", color: "default", icon: <ErrorIcon /> };
  }
};

const OrdersPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState<OrderItem[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await getOrders();
        setOrders(response);
      } catch (error) {
        console.error("Erro ao carregar pedidos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  return (
    <BaseScreen>
      {loading ? (
        <BaseLoader />
      ) : (
        <Container>
          <Title variant="h4">🛒 Meus Pedidos</Title>
          {orders.length > 0 ? (
            <OrdersGrid container spacing={4}>
              {orders.map((order) => {
                const statusDetails = getStatusDetails(order.status);
                return (
                  <Grid item xs={12} sm={6} md={4} key={order.id}>
                    <OrderCard>
                      <StatusBox status={statusDetails.color as StatusColor}>
                        <StatusAvatar
                          status={statusDetails.color as StatusColor}
                        >
                          {statusDetails.icon}
                        </StatusAvatar>
                        <Typography variant="subtitle1" fontWeight="bold">
                          {translateStatus(order.status)}
                        </Typography>
                      </StatusBox>
                      <CardContentStyled>
                        <Typography variant="body2" color="textSecondary">
                          Data: {new Date(order.createdAt).toLocaleDateString()}
                        </Typography>
                        <Divider sx={{ my: 1 }} />
                        <Typography variant="h6" color="#1976d2">
                          Total: R$ {order.total}
                        </Typography>
                        <Divider sx={{ my: 1 }} />
                        {order.items.map((item) => (
                          <ItemBox key={item.id}>
                            <Avatar
                              src={item.product.imagem}
                              alt={item.product.titulo}
                              variant="square"
                              sx={{
                                width: 56,
                                height: 56,
                                mr: 2,
                                boxShadow: 1,
                              }}
                            />
                            <Box>
                              <Typography variant="subtitle1" fontWeight="bold">
                                {item.product.titulo}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                Quantidade: {item.quantity}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                Preço: R$ {item.price}
                              </Typography>
                            </Box>
                          </ItemBox>
                        ))}
                        {order.status === "COMPLETED" && (
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<DownloadIcon />}
                            onClick={() =>
                              navigate(`/downloads?orderId=${order.id}`)
                            }
                            sx={{
                              borderRadius: "20px",
                              px: 4,
                              boxShadow: 2,
                              "&:hover": {
                                boxShadow: 4,
                              },
                            }}
                          >
                            Acessar Downloads
                          </Button>
                        )}
                      </CardContentStyled>
                    </OrderCard>
                  </Grid>
                );
              })}
            </OrdersGrid>
          ) : (
            <EmptyStateBox>
              <EmptyIcon />
              <Typography variant="h5" gutterBottom>
                Nenhum Pedido Encontrado
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Parece que você ainda não fez nenhum pedido. Explore nossos
                produtos e faça sua primeira compra!
              </Typography>
              <ActionButton
                variant="contained"
                color="primary"
                startIcon={<ShoppingCartIcon />}
                onClick={() => navigate("/")}
                sx={{ mt: 2 }}
              >
                Continuar Comprando
              </ActionButton>
            </EmptyStateBox>
          )}
        </Container>
      )}
    </BaseScreen>
  );
};

export default OrdersPage;
