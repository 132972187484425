import React from "react";
import {
  Box,
  Typography,
  LinearProgress,
  useTheme,
  alpha,
} from "@mui/material";

interface CourseProgressProps {
  progress: number;
}

export const CourseProgress: React.FC<CourseProgressProps> = ({ progress }) => {
  const theme = useTheme();

  return (
    <Box sx={{ maxWidth: 400, width: "100%" }}>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
        Progresso do curso: {progress}%
      </Typography>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height: 10,
          borderRadius: 5,
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
          "& .MuiLinearProgress-bar": {
            borderRadius: 5,
            backgroundColor: theme.palette.primary.main,
            transition: "width 0.3s ease-in-out",
          },
        }}
      />
    </Box>
  );
};
