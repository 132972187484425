import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const PaperStyled = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3, 6),
  maxWidth: 800,
  margin: "0 auto",
  borderRadius: 16,
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
}));

export const IconBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(2),
}));

export const SuccessIcon = styled(CheckCircleIcon)(({ theme }) => ({
  fontSize: 80,
  color: theme.palette.success.main,
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: theme.spacing(1),
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(3),
}));

export const DetailBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  textAlign: "center",
}));

export const ActionGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(5),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  padding: theme.spacing(1.5, 4),
  boxShadow: theme.shadows[2],
  "&:hover": {
    boxShadow: theme.shadows[4],
  },
}));
