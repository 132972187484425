import { Helmet } from "react-helmet";
import { Product } from "~/common/entities/product";

interface ProductMetaTagsProps {
  product: Product;
}

const ProductMetaTags = ({ product }: ProductMetaTagsProps) => {
  if (!product) return null;

  const hasDiscount = (product.discount ?? 0) > 0;
  const title = `${product.title} | CR Cursos Preparatórios`;
  const description =
    product.resumedDescription ||
    product.description?.substring(0, 160) ||
    "Confira este produto em nossa loja";
  const imageUrl = product.imageUrl || "/placeholder.jpg";
  const url = window.location.href;
  const price = hasDiscount ? product.discountedPrice : product.price;
  const formattedPrice = price
    ? `R$ ${price.toFixed(2).replace(".", ",")}`
    : "";

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      <link rel="canonical" href={url} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="product" />
      {formattedPrice && (
        <meta property="product:price:amount" content={price?.toString()} />
      )}
      {formattedPrice && (
        <meta property="product:price:currency" content="BRL" />
      )}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
    </Helmet>
  );
};

export default ProductMetaTags;
