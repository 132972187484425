import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { Typography } from "@mui/material";
import React, { useState } from "react";
import { BaseScreen } from "~/common/components/base-screen/base-screen";
import {
  Container,
  FormContainer,
  Header,
  Icon,
  InfoBox,
  InfoContainer,
  InfoItem,
  MapContainer,
  StyledInput,
  StyledTextArea,
  SubmitButton,
  Subtitle,
  Title,
} from "./contact-us.styles";
import baseApi from "~/common/services/base-api";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [status, setStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setStatus("loading");
    setErrorMessage("");

    try {
      const response = await baseApi.post("/contact-us", formData);

      if (response.status === 200) {
        setStatus("success");
        setFormData({ name: "", email: "", subject: "", message: "" });
        setTimeout(() => {
          setStatus("idle");
        }, 3000);
      } else {
        setStatus("error");
        setErrorMessage(
          "Falha ao enviar a mensagem. Por favor, tente novamente.",
        );
      }
    } catch (error: unknown) {
      setStatus("error");
      setErrorMessage(
        "Erro ao enviar a mensagem. Por favor, tente novamente mais tarde." +
          error,
      );
    }
  };

  return (
    <BaseScreen>
      <Container>
        <Header>
          <Title>Fale Conosco</Title>
          <Subtitle>
            Entre em contato através do formulário abaixo. Será um prazer
            atender suas dúvidas, críticas e/ou sugestões.
          </Subtitle>
        </Header>
        <InfoContainer>
          <InfoBox>
            <InfoItem>
              <Icon>
                <EmailIcon />
              </Icon>
              <span>
                <strong>Email:</strong> contato@crcursospreparatorios.com
              </span>
            </InfoItem>
            <InfoItem>
              <Icon>
                <PhoneIcon />
              </Icon>
              <span>
                <strong>Telefone:</strong> (18) 99726-1599
              </span>
            </InfoItem>
            <InfoItem>
              <Icon>
                <AccessTimeIcon />
              </Icon>
              <span>
                <strong>Horário:</strong> Segunda - Sexta | 9:00 - 22:00h
              </span>
            </InfoItem>
            <InfoItem>
              <Icon>
                <LocationOnIcon />
              </Icon>
              <span>
                <strong>Endereço:</strong> Rua Paraguai, 143, Vila Geni - Pres.
                Prudente
              </span>
            </InfoItem>
          </InfoBox>

          <MapContainer>
            <iframe
              title="Localização"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3675.4329!2d-51.388568!3d-22.1209512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1234567890abcdef!2sRua%20Paraguai%2C%20143%20-%20Vila%20Geni%2C%20Presidente%20Prudente%20-%20SP%2C%20Brasil!5e0!3m2!1spt-BR!2sus!4v1600123456789"
              width="100%"
              height="300"
              style={{ border: 0 }}
              loading="lazy"
            ></iframe>
          </MapContainer>

          <FormContainer>
            <Title style={{ fontSize: "2.1rem" }}>Envie Sua Mensagem</Title>
            <StyledInput
              type="text"
              name="name"
              placeholder="Nome"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <StyledInput
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <StyledInput
              type="text"
              name="subject"
              placeholder="Assunto da Mensagem"
              value={formData.subject}
              onChange={handleChange}
              required
            />
            <StyledTextArea
              name="message"
              placeholder="Digite sua Mensagem"
              rows={6}
              value={formData.message}
              onChange={handleChange}
              required
            />
            <SubmitButton
              onClick={handleSubmit}
              disabled={status === "loading"}
            >
              {status === "loading" ? "Enviando..." : "Enviar Mensagem"}
            </SubmitButton>
            {status === "success" && (
              <Typography
                variant="body2"
                color="success.main"
                sx={{ textAlign: "center", mt: 2 }}
              >
                Mensagem enviada com sucesso!
              </Typography>
            )}
            {status === "error" && (
              <Typography
                variant="body2"
                color="error.main"
                sx={{ textAlign: "center", mt: 2 }}
              >
                {errorMessage}
              </Typography>
            )}
          </FormContainer>
        </InfoContainer>
      </Container>
    </BaseScreen>
  );
};

export default ContactPage;
