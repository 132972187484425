import { Button, ListItem, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const PaperStyled = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3, 6),
  maxWidth: 600,
  margin: "0 auto",
  borderRadius: 16,
  boxShadow: theme.shadows[3],
  backgroundColor: "#ffffff",
}));

export const Title = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
}));

export const ListItemStyled = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1, 0),
}));

export const TotalTypography = styled(Typography)({
  fontWeight: "bold",
  textAlign: "right",
});

export const ActionButton = styled(Button)(({ theme }) => ({
  display: "block",
  margin: "0 auto",
  borderRadius: 20,
  padding: theme.spacing(1.5, 4),
  boxShadow: theme.shadows[2],
  "&:hover": {
    boxShadow: theme.shadows[4],
  },
}));
