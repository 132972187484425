import { Theme } from "@material-ui/core";
import { ArrowBack, Mail as MailIcon } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Stack, useMediaQuery } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo_azul from "~/common/assets/logo_azul.webp";
import { CustomSnackBar } from "~/common/components/custom-snackbar";
import Footer from "~/common/components/footer/footer";
import { FirstHeaderSession } from "~/common/components/header/first-header-session/first-header-session";
import baseApi from "~/common/services/base-api";

const RequestPasswordReset: React.FC = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [showFeedback, setShowFeedback] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const smallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await baseApi.post("/user/password-reset/request", { email });
      setIsSuccess(true);
      setFeedbackMessage("Link de recuperação enviado para seu e-mail!");
    } catch (error) {
      setIsSuccess(false);
      setFeedbackMessage("Erro ao solicitar redefinição de senha.");
    } finally {
      setIsLoading(false);
      setShowFeedback(true);
    }
  };

  return (
    <>
      <FirstHeaderSession />
      <Container component="main" sx={{ py: 4 }}>
        <Box sx={{ maxWidth: 600, mx: "auto" }}>
          <Card elevation={3}>
            <IconButton
              onClick={() => navigate(-1)}
              sx={{
                bgcolor: "background.paper",
                boxShadow: 1,
                "&:hover": { bgcolor: "grey.100" },
                marginLeft: 2,
                marginTop: 2,
              }}
            >
              <ArrowBack />
            </IconButton>
            <CardContent sx={{ p: 4 }}>
              <Stack alignItems="center" mb={4}>
                <Box
                  component="img"
                  src={logo_azul}
                  alt="Logo"
                  sx={{
                    width: 96,
                    height: 96,
                    objectFit: "contain",
                  }}
                  loading="lazy"
                />
                <Typography
                  variant={smallScreen ? "h5" : "h4"}
                  fontWeight="bold"
                >
                  Recuperar Senha
                </Typography>
                <Typography color="text.secondary">
                  Digite seu e-mail para receber um link de recuperação de senha
                </Typography>
              </Stack>
              <Box component="form" onSubmit={handleSubmit} noValidate>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Endereço de e-mail"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ mb: 3 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailIcon color="action" />
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  disabled={!email || isLoading}
                  sx={{ py: 1.5 }}
                >
                  {isLoading ? "Enviando..." : "Enviar Link de Recuperação"}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>

        <CustomSnackBar
          approvalSnackbarOpen={showFeedback}
          timeToClose={6000}
          handleCloseApprovalSnackbar={() => setShowFeedback(false)}
        >
          <Alert severity={isSuccess ? "success" : "error"} variant="filled">
            {feedbackMessage}
          </Alert>
        </CustomSnackBar>
      </Container>
      <Footer />
    </>
  );
};

export default RequestPasswordReset;
