import {
  Box,
  Button,
  Grid,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {
  Barcode,
  Calendar,
  CreditCard,
  Lock,
  QrCode,
  User,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import baseApi from "~/common/services/base-api";
import {
  PaymentMethodButton,
  StyledPaper,
  StyledTextField,
} from "~/features/shop/components/pagseguro-payment/pagseguro-payment.styles";
import { useInstallments } from "~/features/shop/hooks/use-installments";
import { updateRole } from "~/redux/slicers/authSlicer";
import { CartItem, clearCart } from "~/redux/slicers/cartSlicer";
import { ApplicationState } from "~/redux/store";

declare global {
  interface Window {
    PagSeguro?: {
      encryptCard: (params: {
        publicKey: string;
        holder: string;
        number: string;
        expMonth: string;
        expYear: string;
        securityCode: string;
      }) => Promise<{ encryptedCard: string }>;
    };
  }
}

interface PaymentSuccessResponse {
  orderId: string;
  paymentId: string;
  paymentStatus: string;
  qrCode?: string;
  boletoUrl?: string;
  qrCodeImage?: string;
  newRole: string;
}

interface PagSeguroPaymentProps {
  cartItems: CartItem[];
  onSuccess: (response: PaymentSuccessResponse) => void;
  onError: (errorMessage: string) => void;
}

interface CardDetails {
  cardNumber: string;
  expirationDate: string;
  cvv: string;
  holderName: string;
  installments: number;
}

const PagSeguroPayment: React.FC<PagSeguroPaymentProps> = ({
  cartItems,
  onSuccess,
  onError,
}) => {
  const [paymentType, setPaymentType] = useState<
    "creditCard" | "ticket" | "pix"
  >("creditCard");
  const [cardDetails, setCardDetails] = useState<CardDetails>({
    cardNumber: "",
    expirationDate: "",
    cvv: "",
    holderName: "",
    installments: 1,
  });
  const [isLoading, setIsLoading] = useState(false);
  const cardBin = cardDetails.cardNumber.replace(/\D/g, "").substring(0, 6);
  const total = calculateTotal();

  const { installments, loading: loadingInstallments } = useInstallments(
    total,
    cardBin,
  );

  const user = useSelector((state: ApplicationState) => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const formatCardNumber = (value: string) => {
    const digits = value.replace(/\D/g, "");
    const groups = digits.match(/.{1,4}/g) || [];
    return groups.join(" ").substr(0, 19);
  };

  const formatExpirationDate = (value: string) => {
    const digits = value.replace(/\D/g, "");
    if (digits.length >= 2) {
      return `${digits.substr(0, 2)}/${digits.substr(2, 2)}`;
    }
    return digits;
  };

  function calculateDiscountPrice(price: number, discount: number) {
    return price - (price * discount) / 100;
  }

  function calculateTotal() {
    return cartItems.reduce(
      (sum, item) =>
        sum +
        calculateDiscountPrice(
          item.product.price ?? 0,
          item.product.discount ?? 0,
        ) *
          item.quantity,
      0,
    );
  }

  const handlePaymentSubmit = async () => {
    if (
      paymentType === "creditCard" &&
      Object.values(cardDetails).some((v) => !v)
    ) {
      onError("Por favor, preencha todos os campos do cartão.");
      return;
    }

    setIsLoading(true);

    try {
      const [month, year] = cardDetails.expirationDate.split("/");

      let encryptedCard;
      if (paymentType === "creditCard") {
        if (!window.PagSeguro) {
          throw new Error("PagSeguro SDK não foi carregado corretamente.");
        }

        const encryption = await window.PagSeguro.encryptCard({
          publicKey: process.env.REACT_APP_PAGSEGURO_PUBLIC_KEY as string,
          holder: cardDetails.holderName,
          number: cardDetails.cardNumber.replace(/\D/g, ""),
          expMonth: month,
          expYear: year?.length === 2 ? `20${year}` : year,
          securityCode: cardDetails.cvv,
        });

        encryptedCard = encryption.encryptedCard;
      }

      const found = installments?.find(
        (i) => i.quantity === cardDetails.installments,
      );
      const totalAmount =
        found?.totalAmount !== undefined
          ? found.totalAmount / 100
          : calculateTotal();

      const payload = {
        paymentType,
        amount: totalAmount,
        cartItems: cartItems.map((item) => ({
          ...item,
          product: {
            ...item.product,
            price: calculateDiscountPrice(
              item.product.price ?? 0,
              item.product.discount ?? 0,
            ),
          },
        })),
        payer: {
          name: user?.name || "",
          email: user?.email || "",
          encryptedCard,
          installments: cardDetails.installments,
        },
        hasCourse: cartItems.some((item) => item.product.type === "CURSOS"),
      };

      const response = await baseApi.post<PaymentSuccessResponse>(
        "/orders/create-order",
        payload,
      );

      dispatch(clearCart());
      onSuccess(response.data);
      dispatch(updateRole(response.data.newRole));
    } catch (error) {
      onError(
        "Erro ao processar pagamento: Por favor verifique as informações fornecidas.",
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledPaper elevation={0}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mb: 4 }}>
        Finalize seu pagamento
      </Typography>

      {isLoading && (
        <Box sx={{ width: "100%", mt: 1, mb: 2 }}>
          <LinearProgress sx={{ borderRadius: 1 }} />
        </Box>
      )}

      <RadioGroup
        value={paymentType}
        onChange={(e) =>
          setPaymentType(e.target.value as "creditCard" | "ticket" | "pix")
        }
        sx={{ display: "flex", flexDirection: "row", gap: 2, mb: 4 }}
      >
        <PaymentMethodButton
          value="creditCard"
          control={<Radio />}
          label={
            <div
              className={`method-content ${paymentType === "creditCard" ? "selected" : ""}`}
            >
              <CreditCard size={20} />
              <span>Cartão de Crédito</span>
            </div>
          }
        />
        <PaymentMethodButton
          value="ticket"
          control={<Radio />}
          label={
            <div
              className={`method-content ${paymentType === "ticket" ? "selected" : ""}`}
            >
              <Barcode size={20} />
              <span>Boleto</span>
            </div>
          }
        />
        <PaymentMethodButton
          value="pix"
          control={<Radio />}
          label={
            <div
              className={`method-content ${paymentType === "pix" ? "selected" : ""}`}
            >
              <QrCode size={20} />
              <span>PIX</span>
            </div>
          }
        />
      </RadioGroup>

      {paymentType === "creditCard" && (
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <StyledTextField
                fullWidth
                label="Número do Cartão"
                value={cardDetails.cardNumber}
                onChange={(e) =>
                  setCardDetails({
                    ...cardDetails,
                    cardNumber: formatCardNumber(e.target.value),
                  })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CreditCard size={20} />
                    </InputAdornment>
                  ),
                }}
                placeholder="0000 0000 0000 0000"
              />
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                fullWidth
                label="Validade"
                value={cardDetails.expirationDate}
                onChange={(e) =>
                  setCardDetails({
                    ...cardDetails,
                    expirationDate: formatExpirationDate(e.target.value),
                  })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Calendar size={20} />
                    </InputAdornment>
                  ),
                }}
                placeholder="MM/AA"
              />
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                fullWidth
                label="CVV"
                value={cardDetails.cvv}
                onChange={(e) =>
                  setCardDetails({
                    ...cardDetails,
                    cvv: e.target.value.replace(/\D/g, "").substr(0, 4),
                  })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock size={20} />
                    </InputAdornment>
                  ),
                }}
                placeholder="000"
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                fullWidth
                label="Nome do Titular"
                value={cardDetails.holderName}
                onChange={(e) =>
                  setCardDetails({
                    ...cardDetails,
                    holderName: e.target.value.toUpperCase(),
                  })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <User size={20} />
                    </InputAdornment>
                  ),
                }}
                placeholder="NOME COMO ESTÁ NO CARTÃO"
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                select
                fullWidth
                label="Parcelas"
                value={cardDetails.installments}
                onChange={(e) =>
                  setCardDetails({
                    ...cardDetails,
                    installments: Number(e.target.value),
                  })
                }
                disabled={loadingInstallments || !cardDetails.cardNumber}
              >
                {installments.map((option) => (
                  <MenuItem key={option.quantity} value={option.quantity}>
                    {`${option.quantity}x de ${(
                      option.amount / 100
                    ).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}${option.interestFree ? " sem juros" : ""} (Total: ${(
                      option.totalAmount / 100
                    ).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })})`}
                  </MenuItem>
                ))}
              </StyledTextField>
            </Grid>
          </Grid>
        </Box>
      )}

      <Button
        variant="contained"
        onClick={handlePaymentSubmit}
        sx={{
          mt: 4,
          py: 2,
          borderRadius: 2,
          textTransform: "none",
          fontSize: "1.1rem",
        }}
        fullWidth
        disabled={isLoading}
      >
        {isLoading
          ? "Processando..."
          : paymentType === "creditCard" && installments !== undefined
            ? `Pagar ${(
                (installments.find(
                  (i) => i.quantity === cardDetails.installments,
                )?.totalAmount ?? calculateTotal() * 100) / 100
              ).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}`
            : `Pagar ${calculateTotal().toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}`}
      </Button>
    </StyledPaper>
  );
};

export default PagSeguroPayment;
