import { styled } from "@mui/material/styles";
import {
  Card,
  Chip,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Button,
  CardMediaProps,
} from "@mui/material";

export const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0 6px 20px rgba(0, 127, 255, 0.1)",
  transition: "all 0.3s ease",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  height: "100%",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: "0 10px 30px rgba(0, 127, 255, 0.2)",
    cursor: "pointer",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    height: "auto",
  },
}));

export const DiscountChip = styled(Chip)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2),
  left: theme.spacing(2),
  zIndex: 100,
  backgroundColor: "#ff6f61",
  color: "#fff",
  fontSize: "0.875rem",
}));

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: theme.spacing(3),
}));

export const StyledCardMedia = styled(CardMedia)<CardMediaProps>({
  height: "220px",
  objectFit: "cover",
  filter: "brightness(0.9)",
});

export const ProductTitle = styled(Typography)(({ theme }) => ({
  color: "#333",
  marginBottom: theme.spacing(1),
  transition: "color 0.3s ease",
  "&:hover": { color: "#007FFF" },
}));

export const PriceText = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: theme.palette.primary.main,
}));

export const ButtonGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}));

export const PrimaryButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#007FFF",
  borderRadius: theme.shape.borderRadius,
  color: "#fff",
  fontSize: "1rem",
  padding: theme.spacing(1),
  fontWeight: "bold",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: "#005BBB",
    transform: "scale(1.02)",
  },
}));

export const SecondaryButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#eaeaea",
  color: "#333",
  borderRadius: theme.shape.borderRadius,
  fontSize: "1rem",
  padding: theme.spacing(1),
  fontWeight: "bold",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: "#d6d6d6",
    transform: "scale(1.02)",
  },
}));
