import { styled } from "@mui/material/styles";
import { Box, Typography, Button } from "@mui/material";

export const ImageContainer = styled(Box)`
  position: relative;
  text-align: center;
  margin-bottom: 40px;
  height: 400px;
  width: 100%;
  max-width: 1056px;
  margin: 0 auto 40px auto;
`;

export const StyledImage = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  border: 2px solid #e0e0e0;
`;

export const ContentBox = styled(Box)(({ theme }) => ({
  padding: "40px",
  borderRadius: "8px",
  backgroundColor: "#f9f9f9",
  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
  marginBottom: "40px",
  [theme.breakpoints.down("sm")]: {
    padding: "10px",
    marginBottom: "0px",
  },
}));

export const ContentText = styled(Typography)(({ theme }) => ({
  lineHeight: 1.8,
  fontSize: "1.2rem",
  textAlign: "justify",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.0rem",
  },
}));

export const ShareActions = styled(Box)`
  text-align: center;
  margin-bottom: 40px;
`;

export const ShareText = styled(Typography)`
  font-weight: bold !important;
  margin-bottom: 24px !important;
`;

export const StyledButton = styled(Button)`
  text-transform: none !important;
  font-size: 1rem !important;
`;
