import { styled } from "@mui/material/styles";
import {
  Card,
  Box,
  Avatar,
  Button,
  Typography,
  Grid,
  CardContent,
} from "@mui/material";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";

export const Container = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(2, 4),
  pb: theme.spacing(4),
}));

export const Title = styled(Typography)({
  color: "#1976d2",
  fontWeight: "bold",
  textAlign: "center",
});

export const OrdersGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const OrderCard = styled(Card)(({ theme }) => ({
  borderRadius: 16,
  boxShadow: theme.shadows[3],
  transition: "transform 0.3s",
  backgroundColor: "#ffffff",
  "&:hover": {
    transform: "translateY(-10px)",
  },
}));

interface StatusBoxProps {
  status: "success" | "warning" | "error";
}

export const StatusBox = styled(Box)<StatusBoxProps>(({ status, theme }) => ({
  backgroundColor:
    status === "success"
      ? "#d4edda"
      : status === "warning"
        ? "#fff3cd"
        : "#f8d7da",
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StatusAvatar = styled(Avatar)<StatusBoxProps>(
  ({ status, theme }) => ({
    backgroundColor:
      status === "success"
        ? "#28a745"
        : status === "warning"
          ? "#ffc107"
          : "#dc3545",
    marginRight: theme.spacing(1),
  }),
);

export const CardContentStyled = styled(CardContent)({
  textAlign: "center",
});

export const ItemBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: theme.spacing(2),
}));

export const EmptyStateBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
}));

export const EmptyIcon = styled(SentimentDissatisfiedIcon)(({ theme }) => ({
  fontSize: 60,
  color: "#484848",
  marginBottom: theme.spacing(2),
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  padding: theme.spacing(1, 4),
  boxShadow: theme.shadows[2],
  "&:hover": {
    boxShadow: theme.shadows[4],
  },
}));
