const IconsSection = () => {
  const fundationYear = 1997;
  const currentYear = new Date().getFullYear();
  const yearsOfExperience = currentYear - fundationYear;

  return (
    <section className="icons-section" id="icons-section">
      <div className="icons-grid">
        <div className="icons-card">
          <div className="icons-icon">📚</div>
          <h3 className="icons-title">Material de Qualidade</h3>
          <p className="icons-desc">
            Conteúdos atualizados e focados nos concursos mais recentes.
          </p>
        </div>
        <div className="icons-card">
          <div className="icons-icon">💰</div>
          <h3 className="icons-title">Preços Justos</h3>
          <p className="icons-desc">
            Investimento acessível com ótimo custo-benefício.
          </p>
        </div>
        <div className="icons-card">
          <div className="icons-icon">👩‍🏫</div>
          <h3 className="icons-title">Professores Especializados</h3>
          <p className="icons-desc">
            Corpo docente experiente e qualificado em concursos públicos.
          </p>
        </div>

        <div className="icons-card">
          <div className="icons-icon">⏰</div>
          <h3 className="icons-title">{yearsOfExperience} Anos no Mercado</h3>
          <p className="icons-desc">
            Tradição e excelência em preparação para concursos.
          </p>
        </div>
      </div>
    </section>
  );
};

export default IconsSection;
