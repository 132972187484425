import { styled } from "@mui/material/styles";
import { FormControlLabel, Paper, TextField } from "@mui/material";

export const PaperStyled = styled(Paper)`
  padding: 24px;
`;

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: "1000px",
  margin: "0 auto",
  borderRadius: "12px",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
}));

export const PaymentMethodButton = styled(FormControlLabel)(({ theme }) => ({
  flex: 1,
  margin: "8px",
  "& .MuiFormControlLabel-label": {
    width: "100%",
  },
  "& .MuiRadio-root": {
    display: "none",
  },
  "& .method-content": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "16px",
    border: `2px solid ${theme.palette.grey[300]}`,
    borderRadius: "8px",
    transition: "all 0.3s ease",
    cursor: "pointer",
    gap: "8px",
    "&.selected": {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light + "20",
    },
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
    "&.Mui-focused": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));
