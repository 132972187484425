import { Step, StepLabel } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "~/redux/store";
import {
  Container,
  ErrorAlertStyled,
  PaperStyled,
  StepperStyled,
  Title,
} from "./checkout.styles";
import { BaseScreen } from "~/common/components/base-screen/base-screen";
import Confirmation from "~/features/shop/components/confirmation/confirmation";
import OrderSummary from "~/features/shop/components/order-sumary/order-sumary";
import PagseguroPayment from "~/features/shop/components/pagseguro-payment/pagseguro-payment";

interface PaymentInfo {
  id: string;
  status: string;
  qrCode?: string;
  qrCodeImage?: string;
  boletoUrl?: string;
}

interface PaymentResponse {
  orderId: string;
  paymentId: string;
  paymentStatus: string;
  qrCode?: string;
  qrCodeImage?: string;
  boletoUrl?: string;
}

const steps = ["Resumo do Pedido", "Pagamento", "Confirmação"];

const CheckoutPage: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [orderId, setOrderId] = useState<string | null>(null);
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo | null>(null);
  const [error, setError] = useState<string | null>(null);

  const cartItems = useSelector((state: ApplicationState) => state.cart.items);

  const handleNext = () => setActiveStep((prev) => prev + 1);

  const handlePaymentSuccess = (response: PaymentResponse) => {
    setOrderId(response.orderId);
    setPaymentInfo({
      id: response.paymentId,
      status: response.paymentStatus,
      qrCode: response.qrCode,
      qrCodeImage: response.qrCodeImage,
      boletoUrl: response.boletoUrl,
    });
    handleNext();
  };

  const handlePaymentError = (errorMessage: string) => setError(errorMessage);

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <OrderSummary onNext={handleNext} />;
      case 1:
        return (
          <PagseguroPayment
            cartItems={cartItems}
            onSuccess={handlePaymentSuccess}
            onError={handlePaymentError}
          />
        );
      case 2:
        return <Confirmation orderId={orderId} paymentInfo={paymentInfo} />;
      default:
        return "Etapa desconhecida";
    }
  };

  return (
    <BaseScreen>
      <Container>
        <Title variant="h4">Checkout</Title>
        <StepperStyled activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </StepperStyled>
        <PaperStyled elevation={3}>
          {error && (
            <ErrorAlertStyled severity="error">{error}</ErrorAlertStyled>
          )}
          {renderStepContent(activeStep)}
        </PaperStyled>
      </Container>
    </BaseScreen>
  );
};

export default CheckoutPage;
