import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BaseScreen } from "~/common/components/base-screen/base-screen";
import { Home, ArrowLeft } from "lucide-react";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <BaseScreen>
      <Container maxWidth="md">
        <Box
          sx={{
            minHeight: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Box>
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: "7rem", sm: "10rem" },
                fontWeight: 800,
                color: "primary.main",
                opacity: 0.9,
                mb: 2,
              }}
            >
              404
            </Typography>

            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                mb: 3,
                color: "text.primary",
              }}
            >
              Página não encontrada
            </Typography>

            <Typography
              color="text.secondary"
              sx={{ mb: 6, maxWidth: 460, mx: "auto" }}
            >
              A página que você está procurando não existe ou foi movida.
            </Typography>

            <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
              <Button
                variant="contained"
                size="large"
                onClick={() => navigate("/")}
                startIcon={<Home size={20} />}
              >
                Início
              </Button>
              <Button
                variant="outlined"
                size="large"
                onClick={() => navigate(-1)}
                startIcon={<ArrowLeft size={20} />}
              >
                Voltar
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </BaseScreen>
  );
};

export default NotFoundPage;
