import baseApi from "~/common/services/base-api";
import {
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import { StudentCourse } from "~/features/student-area/types/student-area";
import { useMediaQuery, useTheme } from "@mui/system";

export const useCourseProgress = (
  setLocalCourse: Dispatch<SetStateAction<StudentCourse | undefined>>,
) => {
  const calculateProgress = (course: StudentCourse): number => {
    const totalLessons = course.modules.reduce(
      (acc, m) => acc + m.lessons.length,
      0,
    );
    const completedLessons = course.modules.reduce(
      (acc, m) => acc + m.lessons.filter((l) => l.completed).length,
      0,
    );
    return Math.round((completedLessons / totalLessons) * 100);
  };

  const handleCompleteLesson = async (lessonId: number, completed: boolean) => {
    try {
      await baseApi.post(`/lessons/${lessonId}/complete`, { completed });

      setLocalCourse((prevCourse) => {
        if (!prevCourse) return prevCourse;

        const updatedCourse = {
          ...prevCourse,
          modules: prevCourse.modules.map((mod) => ({
            ...mod,
            lessons: mod.lessons.map((les) =>
              les.id === lessonId ? { ...les, completed } : les,
            ),
          })),
        };

        return {
          ...updatedCourse,
          progress: calculateProgress(updatedCourse),
        };
      });
    } catch (error) {
      console.error("Error updating lesson status:", error);
    }
  };

  return {
    handleCompleteLesson,
    calculateProgress,
  };
};

export const useSelectedContent = (
  course: StudentCourse | undefined,
  lessonViewRef: React.RefObject<HTMLDivElement>,
) => {
  const [selectedModuleId, setSelectedModuleId] = useState<number | null>(null);
  const [selectedLessonId, setSelectedLessonId] = useState<number | null>(null);
  const [openModules, setOpenModules] = useState<number[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (
      course?.modules?.length &&
      selectedModuleId === null &&
      selectedLessonId === null
    ) {
      const sortedModules = [...course.modules].sort(
        (a, b) => a.order - b.order,
      );
      const firstModule = sortedModules[0];

      if (firstModule) {
        setSelectedModuleId(firstModule.id);
        setOpenModules([firstModule.id]);

        if (firstModule.lessons?.length) {
          const sortedLessons = [...firstModule.lessons].sort(
            (a, b) => a.order - b.order,
          );
          setSelectedLessonId(sortedLessons[0].id);
        }
      }
    }
  }, [course, selectedModuleId, selectedLessonId]);

  const handleModuleClick = useCallback((moduleId: number) => {
    setOpenModules((prev) =>
      prev.includes(moduleId)
        ? prev.filter((id) => id !== moduleId)
        : [...prev, moduleId],
    );
  }, []);

  const handleLessonClick = useCallback(
    (moduleId: number, lessonId: number) => {
      setSelectedModuleId(moduleId);
      setSelectedLessonId(lessonId);

      setOpenModules((prev) =>
        prev.includes(moduleId) ? prev : [...prev, moduleId],
      );

      if (isMobile && lessonViewRef.current) {
        setTimeout(() => {
          lessonViewRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }, 100);
      }
    },
    [],
  );

  const getSelectedLesson = useCallback(() => {
    if (!course || selectedModuleId === null || selectedLessonId === null)
      return null;

    const module = course.modules.find((m) => m.id === selectedModuleId);
    return module?.lessons.find((les) => les.id === selectedLessonId) || null;
  }, [course, selectedModuleId, selectedLessonId]);

  return {
    selectedModuleId,
    selectedLessonId,
    openModules,
    handleModuleClick,
    handleLessonClick,
    getSelectedLesson,
  };
};
